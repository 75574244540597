/**
 * Imports
 */
import { DATES_MAP } from './constants';



/**
 * Deep clone an object
 */
export function getDeepClone(obj) {
    return obj;
};

/**
 * Get difference in arrays
 *
 * @param {array} a
 * @param {array} b
 * @param {array} keys
 */
export function arrDiff(a, b, keys) {
    function comparer(c) {
        return (d) => (c.filter((e) => isEqual(e, d)).length === 0);
    }

    const f = !isEmpty(keys) ? a.map(o => keys.reduce((a, k) => ({ ...a, [k]: o[k] }), {})) : a;
    const g = !isEmpty(keys) ? b.map(o => keys.reduce((a, k) => ({ ...a, [k]: o[k] }), {})) : b;

    let onlyInA = f.filter(comparer(g));
    let onlyInB = g.filter(comparer(f));

    return onlyInA.concat(onlyInB);
}





/**
 * Convert string to camelcase
 *
 * @param {string} text
 */
export function camelCase(text) {
    const words = text.split(' ');
    const converted = words.map(w => w.charAt(0).toUpperCase()+w.toLowerCase().slice(1));

    return converted.join(' ');
}





/**
 * Deep clone an object
 */
export function clone(obj) {
    const tmp = obj;
    return JSON.parse(JSON.stringify(tmp));
}





/**
 * Convert to date obj
 *
 * @param {string} date
 */
export function convertToDateObj(date) {
    if (!date || date === '-') return undefined;

    const d = new Date(date);
    return { d: d.getDate(), m: parseInt(d.getMonth()), y: d.getFullYear() };
}





/**
 * Date conversion
 *
 * @param {object} obj
 */
export function convertDateObjToStr(obj) {

    if (!obj) return '';

    const d = obj.d < 10 ? '0' + obj.d : obj.d;
    const m = obj.m < 9 ? '0' + ((parseInt(obj.m) + 1)) : (parseInt(obj.m) + 1);

    return `${d}/${m}/${obj.y}`;

}





/**
 * Convert to string date
 *
 * @param {string} str
 */
export function convertStrToDate(str) {
    if (!str) return;

    const arr = str.split('/');
    return arr.length ? new Date(arr[2], arr[1] - 1, arr[0]) : '';
}





/**
 * Convert date obj to time
 *
 * @param {number} d
 * @param {number} m
 * @param {number} y
 */
export function convertToTime({ d, m, y }) {
    return (new Date(y, m, d)).getTime();
}





/**
 * Add/subtract days from date
 *
 * @param {datetime} date
 * @param {integer} days
 */
export function getDays(date, days) {
    const tmpDate = new Date(date);
    tmpDate.setDate(tmpDate.getDate() + days);
    return tmpDate;
}





/**
 * Get date range
 *
 * @param {string} range
 */
export function getDateRange(range, time = false) {
    const startdate = new Date();
    const enddate = getDays(startdate, DATES_MAP[range]);
    const start = time ? startdate.getTime() : parseInt(startdate.getFullYear()+('0'+(startdate.getMonth()+1)).slice(-2)+('0'+startdate.getDate()).slice(-2));
    const end = time ? enddate.getTime() : parseInt(enddate.getFullYear()+('0'+(enddate.getMonth()+1)).slice(-2)+('0'+enddate.getDate()).slice(-2));

    return { end, start };
}





/**
 * Check if var is empty
 *
 * @param {array|object|string} v
 */
export function isEmpty(v) {
    if (!v || v === undefined) return true;
    if (v.length !== undefined) return v.length === 0;
    if (typeof v === 'object') return Object.keys(v).length === 0 && v.constructor === Object;
}



export function truncate(str, len) {
    len = len ? len : 24;
    if (str.length < len) {
        return str
    } else {
        return str.substr(0, len - 3) + '...';
    }
}




/**
 * Deep equality check
 * (https://dmitripavlutin.com/how-to-compare-objects-in-javascript/#4-deep-equality)
 *
 * @param {array|object|string|number} a
 * @param {array|object|string|number} b
 */
export function isDeepEqual(a, b) {
    const ak = Object.keys(a);
    const bk = Object.keys(b);

    if (ak.length !== bk.length) return false;

    for (const k of ak) {
        const av = a[k];
        const bv = b[k];
        const areObjects = isObject(av) && isObject(bv);

        if ((areObjects && !isEqual(av, bv))
            || (!areObjects && av !== bv)) return false;
    }

    return true;
}





/**
 * JSON equality check
 *
 * @param {array|object|string|number} a
 * @param {array|object|string|number} b
 */
export function isEqual(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
}





/**
 * Check if var is object
 * (https://dmitripavlutin.com/how-to-compare-objects-in-javascript/#4-deep-equality)
 *
 * @param {object} a
 */
const isObject = (a) => {
    return a !== null && typeof a === 'object';
}





/**
 * Parse JWT
 *
 * @param {string} token
 */
export function parseJWT(token) {
    if (!token) return '';

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payload = decodeURIComponent(atob(base64).split('').map(c => '%'+('00'+c.charCodeAt(0).toString(16)).slice(-2)).join(''));

    return JSON.parse(payload);
}





/**
 * Check if JSON
 *
 * @param {string} str
 */
export function isJSON(str) {
    if (typeof str !== 'string') return false;

    try {
        JSON.parse(str);
        return true;
    } catch (error) {
        return false;
    }
}

export function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}




