/**
 * Imports
 */
import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../Helpers/components/presentational/Logo';
import { formatBotSrcEventData, logEvent } from './../../../../events/eventsUtils'




/**
 * Header component
 */
const Header = ({setSidebarOpen, sidebarOpen, isReceiverPage}) => {


    function handleNavBarClickEvent(entity) {
        const event_data = formatBotSrcEventData({
            event_type: "click",
            bot_src: {
                screen_name: isReceiverPage ? "centerfresh_receiver_screen" : "centerfresh_sender_screen",
                sec_title: 'navbar',
                entity_name: entity,
                entity_id: entity.replace(/\s+/g, '_').toLowerCase(),
                entity_type: "button",
            }
        });
        logEvent(event_data);
    }


    /**
     * Component view
     */
    return (
        <header className={`c-header ${sidebarOpen ? 'open' : ''}`}>
            <div className="o-layout o-layout--between@md o-layout--middle">
                <div className="o-layout__item u-1/5 u-align-center c-sidebar__btn u-hidden@md" onClick={()=> setSidebarOpen(!sidebarOpen)}>
                    <svg className="fake-icon" viewBox="0 0 100 80">
                        <rect width="100" height="10" rx="8" fill="#FFF"></rect>
                        <rect y="25" width="100" height="10" rx="8" fill="#FFF"></rect>
                        <rect y="50" width="100" height="10" rx="8" fill="#FFF"></rect>
                    </svg>
                </div>
                <div className="o-layout__item u-3/5  u-1/3@md u-align-center u-align-left@md" onClick={() => handleNavBarClickEvent('Logo')}>
                    <Logo />
                </div>
                <div className="o-layout__item u-1/5 u-1/2@md">
                    <ul className="o-list-inline u-align-right u-margin-bottom-none u-visible-visually@md">
                        <div className="o-list-inline__item u-padding-horizontal" onClick={() => handleNavBarClickEvent('Home')}>
                            <Link to="/">Home</Link>
                        </div>
                        <div className="o-list-inline__item u-padding-horizontal" onClick={() => handleNavBarClickEvent('FAQs')}>
                            <Link to="/faq">FAQs</Link>
                        </div>
                        <div className="o-list-inline__item u-padding-horizontal" onClick={() => handleNavBarClickEvent('Terms & Conditions')}>
                            <Link to="/terms">Terms & Conditions</Link>
                        </div>
                    </ul>
                </div>
            </div>
        </header>
    );

}

export default Header;




