import { reducer as homePageReducer, initialState as homePageState } from "./pages/Home/reducer"

const initialState = {
    homePageView : homePageState
}

export default (state = initialState, action) => {
    let newState = {
        homePageView: homePageReducer(state.homePageView, action)
    }
    return newState;
};

