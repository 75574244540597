/**
 * Imports
 */
import * as actionTypes from './actionTypes';
import * as utils from '../../utils';





/**
 * Default state
 */
export const initialState = {
    fetching_home: true,
    fetched_home: false,
    songs: [],
    title: "",
    image: "",
    sender: "",
    receiver: "",
    message: "",
    voice: "",
    token: "",
    playlist_id: ""
}





/**
 * Home reducer actions
 */
export function reducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.FETCHED_HOME: {
            let new_state = utils.getDeepClone(state);
            new_state.fetching_home = false;
            new_state.fetched_home = true;
            return new_state;
        }

        case actionTypes.FETCHING_HOME: {
            let new_state = utils.getDeepClone(state);
            new_state.fetching_home = true;
            new_state.fetched_home = false;
            return new_state;
        }

        case actionTypes.FETCHED_PLAYLIST_DATA: {
            let new_state = utils.getDeepClone(state);
            new_state.title = action.payload.title;
            new_state.image = action.payload.image;
            new_state.songs = action.payload.songs;
            new_state.sender = action.payload.sender;
            new_state.receiver = action.payload.receiver;
            new_state.message = action.payload.message;
            new_state.voice = action.payload.voice;
            new_state.playlist_id = action.payload.playlist_id; 
            return new_state;
        }

        case actionTypes.FETCHED_SENDER_PAGE_DATA: {
            let new_state = utils.getDeepClone(state);
            new_state.title = action.payload.title;
            new_state.image = action.payload.image;
            new_state.songs = action.payload.songs;
            new_state.token = action.payload.token;
            new_state.receiver = action.payload.receiver;
            new_state.playlist_id = action.payload.playlist_id; 
            return new_state;
        }

        default:
            return state;
    }

}




