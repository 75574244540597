/**
 * Imports
 */
import React from 'react';





/**
 * Reusable error boundary
 */
class ErrorBoundary extends React.Component {

    /**
     * Initialize
     */
    constructor(props) {
        super(props);

        this.state = {
            hasError: false
        };
    }


    /**
     * Update state for next render
     */
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }


    /**
     * Actions when error is caught
     */
    componentDidCatch(error) {
        console.error('APP ERROR:',error);
    }


    /**
     * Render error or module
     */
    render() {
        if (this.state.hasError) return <h2>Something went wrong</h2>;

        return this.props.children;
    }
}





/**
 * Exports
 */
export default ErrorBoundary;




