/**
 * Imports
 */
import * as actionTypes from './actionTypes';


/**
 * Fire on home fetch start
 */
export const fetchingHome = () => ({
    type: actionTypes.FETCHING_HOME,
});


/**
 * Fire on home fetch complete
 */
export const fetchedHome = (payload) => ({
    type: actionTypes.FETCHED_HOME,
    payload
});


/**
 * Fire on playlist data fetch complete
 */
export const fetchedPlaylistData = (payload) => ({
    type: actionTypes.FETCHED_PLAYLIST_DATA,
    payload
});


/**
 * Fire on fetching sender page playlist preview
 */
export const fetchedSenderPageData = (payload) => ({
    type: actionTypes.FETCHED_SENDER_PAGE_DATA,
    payload
}) 