import axios from 'axios';
import { ENDPOINT } from "../api_handler";
import { getGeoCookieData, getCookie } from './cookieManager';

const STATS_BASE_URL = 'https://' + ENDPOINT + '/stats.php'; 

export function formatBotSrcEventData(params) {

    let {bot_src, event_type} = params;

    //console.log("EVENT__ input: ", { bot_src, event_type} );

    let initBotSrc = {
        "bot_src": {
            "screen_name": "NULL",
            "sec_title": "NULL",
            "entity_name": "NULL",
            "entity_id": "NULL",
            "entity_type": "NULL"
        }
    }

    let output = {
        "ev": getPrefix() + event_type,
        bot_src: {
            ...initBotSrc.bot_src,
            ...bot_src
        }
    }

    //console.log("EVENT__ output: ", output);
    return output;
}


function appendMetaData(event){

    let geo = getGeoCookieData();
    let device_id = getCookie("B");

    let metaData = {
        cc: geo == null ? 'NULL' : geo["country"],
        state: geo == null ? 'NULL' : geo["state"],
        city: geo == null ? 'NULL' : geo["city"],
        ctx: isMobile() ? 'wap' : 'site',
        parent_partner_id: 'centerfresh_microsite',
        device_id: device_id ? device_id : 'NULL'
    };

    let event_data = {
        ...event,
        ...metaData
    };

    return event_data;
}


export async function logEvent(event){

    var event_data = appendMetaData(event);
    let data = new FormData();
    data.append("batch_params", JSON.stringify([event_data]));

    const config = {
        withCredentials: true,
        "headers": { 'Content-Type': 'multipart/form-data' }
    };
    const req = axios.post(STATS_BASE_URL, data, config);
    const res = await req;
    //console.log("API__ Event Logged");
    return res.data;
}


export function handleSuccessEvent({context,contextID}) {
    let success_event_data = {
        "ev": getPrefix() + "success",
        bot_src: {
            context,
            contextID
        }
    }
    logEvent(success_event_data);
    return;
}

export function handleFailureEvent({context,contextID,error_msg}) {
    let failure_event_data = {
        "ev": getPrefix() + "failure",
        bot_src: {
            context,
            contextID,
            error_msg
        }
    }
    logEvent(failure_event_data);
    return;
}



function getPrefix(){
    return isMobile() ? "wap:" : "site:";
};



/**
 * Check mobile
 */
function isMobile() {
    let navigator = navigator ? navigator : undefined;
    if (navigator) {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const winWidth = window.innerWidth !== undefined ? window.innerWidth : document.documentElement.clientWidth;
        return winWidth < 980 || (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent));
    }
    return false;
};