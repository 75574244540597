import axios from 'axios';

import { MESSAGE_MAPPINGS } from './constants';
import { handleSuccessEvent, handleFailureEvent } from './events/eventsUtils'; 

// NOTE : Endpoint needed to be changed in the api_handler.js file in the server folder too. 
export const ENDPOINT = "www.jiosaavn.com";
export const API_ENDPOINT = "https://" + ENDPOINT + "/api.php?";
export const ADDITIONAL_PARAMS = "&api_version=4&_format=json&_marker=0";

export async function getSongAuthUrl(encrypted_url) {

    const req = axios.get( API_ENDPOINT + '__call=song.generateAuthToken' + ADDITIONAL_PARAMS,
        {
            withCredentials: true,
            params: {
                url: encrypted_url,
                bitrate: 128
            }
        }
    );
    const res = await req;
    //console.log("API__ Song Auth URL ",res.data);
    return res.data.auth_url;
}



export async function getNameReco(name) {
    if(name.length <=1 )    return [];
    const req = axios.get( API_ENDPOINT + '__call=centerfresh.getNameReco' + ADDITIONAL_PARAMS,
        {
            withCredentials: true,
            params: {
                name
            }
        }
    );
    const res = await req;
    //console.log("API__ Name Recommendations ",res.data);
    return res.data;
}



export async function getPlaylistAndSaveDetails(body) {

    var event_context = {
        context: "centerfresh_playlist",
        contextID: "getPlaylistAndSaveDetails"
    }
    try {

        const req = axios.get( API_ENDPOINT + '__call=centerfresh.getPlaylistAndSaveDetails' + ADDITIONAL_PARAMS,
            {
                withCredentials: true,
                params: {
                    token: Date.now() + window.crypto.randomUUID().substring(0,5),
                    relation: body.relation[0],
                    mood: body.mood[0],
                    language: body.language[0],
                    sender: body.user_name[0],
                    receiver: body.dedication[0],
                    message: MESSAGE_MAPPINGS.indexOf(body.message[0]),
                    voice: body.tone[0]
                }
            }
        );
        const res = await req;
        //console.log("API__ Sender Page Submission Response ",res.data);
        
        if(res.status < 200 || res.status > 400){
            event_context.error_msg = "Status Code Not 200";
            handleFailureEvent({...event_context});
        }
        else {
            if(res.data.songs.length > 0)  handleSuccessEvent({...event_context});
            else {
                event_context.error_msg = "No Songs Found / Invalid Playlist ID"; 
                handleFailureEvent({...event_context});
            }
        }
        
        return res.data;
    } catch (e) {
        event_context.error_msg = e.message;
        handleFailureEvent({...event_context});
        return {};
    }
}



export async function getShortenedURL(token) {

    const req = axios.get( API_ENDPOINT + '__call=centerfresh.getShortenedURL' + ADDITIONAL_PARAMS,
        {
            withCredentials: true,
            params: {
                token
            }
        }
    );
    const res = await req;
    //console.log("API__ URL SHORTENER Response ",res.data);
    return res.data.shorturl;

}