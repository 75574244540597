/**
 * Imports
 */
import React, { useEffect, useState, useRef } from 'react';
import { renderToString } from 'react-dom/server'

import Accordion from '../../../../components/Helpers/components/presentational/Accordion';
import Select from '../../../../components/Select/components/Container/Select';

import { formatBotSrcEventData, logEvent } from '../../../../events/eventsUtils';
import { DUMMY_FAQ as faqData } from '../../../../constants';

/**
 * Home page view
 */
const Terms = ({}) => {

    /**
     * On page load
     */
     useEffect(() => {
        const view_event_data = formatBotSrcEventData({
            event_type : "view",
            bot_src: {
                screen_name: "centerfresh_terms_screen"
            }
        });
        logEvent(view_event_data);
    }, []);

    /**
     * Render view
     */
    return (
        <main className='scroll u-background-white legalese'>
            <section className="o-layout o-layout--middle o-layout--flush u-background-darker-blue u-position-relative u-padding-vertical-huge@md u-padding-vertical-large angle" style={{marginTop: '-1px'}}>
                    <div className="o-layout__item u-3/5@md f-z-index">
                    <div className="o-wrapper">
                        <h1 className="u-color-white">Terms and Conditions</h1>
                    </div>
                </div>
            </section>


            <section>
                <div className="o-wrapper u-padding-vertical-large u-padding-horizontal-large u-padding-horizontal-massive@md">
                <div className="legalese">
                    <p class="p1"><span class="s1"><b>“CENTER FRESH LOVE SONGS”</b></span></p>
                    <p class="p2"><span class="s1"><b></b></span><br/></p>
                    <p class="p1"><b>CAMPAIGN TERMS AND CONDITIONS</b></p>
                    <p class="p2"><b></b><br/></p>
                    <p class="p3"><b>The following Terms and Conditions (“</b><span class="s1"><b>Rules</b></span><b>”) govern your participation in the “Center fresh Love Songs” campaign ("</b><span class="s1"><b>Campaign</b></span><b>") as sponsored by Perfetti Van Melle India Private Limited (“Center fresh”) and provided by Saavn Media Limited (“</b><span class="s1"><b>JioSaavn</b></span><b>”). By participating in the Campaign, you (“</b><span class="s1"><b>Participant”, </b></span><b>“</b><span class="s1"><b>You</b></span><b>”, “</b><span class="s1"><b>Your</b></span><b>”) hereby agree to be bound by these Rules. These Rules are an integral part of the </b>
                    <a href="https://www.jiosaavn.com/corporate/terms/" target="_blank"><span class="s2"><b>JioSaavn Terms of Use</b></span></a>
                    <b> and </b>
                    <a href="https://www.jiosaavn.com/corporate/privacy/" target="_blank"><span class="s3"><b>JioSaavn Privacy Policy</b></span></a>
                    <b>, and by participating in the Campaign you hereby irrevocably agree to the terms as set out in </b>
                    <a href="https://www.jiosaavn.com/corporate/terms/" target="_blank"><span class="s2"><b>JioSaavn Terms of Use</b></span></a><b> and the 
                    </b>
                    <a href="https://www.jiosaavn.com/corporate/privacy/" target="_blank"><span class="s3"><b>JioSaavn Privacy Policy</b></span></a>
                    <b> .</b></p>
                    <p class="p2"><b></b><br/></p>
                    <ul class="ul1">
                      <li class="li4"><b></b><span class="s1"><b>WHAT IS THE CAMPAIGN ALL ABOUT?</b></span></li>
                    </ul>
                    <p class="p5"><span class="s1"><b></b></span><br/></p>
                    <p class="p6">A Participant who agrees to participate in the Campaign can share a dedication (“<span class="s1"><b>Sender</b></span>”), with a recipient of their choosing (“<span class="s1"><b>Receiver</b></span>”) through the Campaign which will be hosted on a microsite at<span class="Apple-converted-space">  </span>
                    <a href="https://centerfreshlovesongs.jiosaavn.com" target="_blank"><span class="s3">https://centerfreshlovesongs.jiosaavn.com</span></a>
                    <span class="s4"> </span>on the JioSaavn Platform (“<span class="s1"><b>Platform</b></span>”).<span class="Apple-converted-space"> </span></p>
                    <p class="p2"><br/></p>
                    <p class="p6">The Sender will need to provide certain details in order to create a unique link with a playlist (“Playlist”) that may be shared with the Receiver. The unique link which will be created and shared by the Sender with the Receiver will also let the Receiver access an audio message and stream the Playlist <span class="s1"><b>only</b></span> on the Platform.<span class="Apple-converted-space"> </span></p>
                    <p class="p2"><br/></p>
                    <p class="p6">The Campaign Start Date will be January 1, 2023 and will continue up to and including June 30, 2023.<span class="Apple-converted-space"> </span></p>
                    <p class="p2"><br/></p>
                    <p class="p6"><span class="s1"><b>Please note:</b></span> The above paragraph is provided solely as a summary of the Campaign. Your eligibility and participation in the Campaign are governed by the specific terms and conditions as set forth below.<span class="Apple-converted-space"> </span></p>
                    <p class="p2"><br/></p>
                    <ul class="ul1">
                      <li class="li4"><b>HOW TO PARTICIPATE IN THE CAMPAIGN?</b></li>
                    </ul>
                    <p class="p5"><b></b><br/></p>
                    <p class="p6">In order to participate in the Campaign, the Sender has to do the following:</p>
                    <p class="p7"><br/></p>
                    <ul class="ul1">
                      <li class="li3">Purchase a unit of the <span class="s1">Center fresh Fruity Mint Flavour</span> chewing gum (“Product”) and scan the Quick Response Barcode (“QR Code”) on the Product.<span class="Apple-converted-space"> </span></li>
                    </ul>
                    <p class="p8"><br/></p>
                    <ul class="ul1">
                      <li class="li3">Once the Sender scans the QR Code on the Product<span class="s5">, they will get a link that will </span>take them to the Platform via the browser of their device (Mobile or Desktop).<span class="Apple-converted-space"> </span></li>
                    </ul>
                    <p class="p8"><br/></p>
                    <ul class="ul1">
                      <li class="li4">Once <span class="s5">the Sender</span> is on the Platform, <span class="s5">they </span>will be prompted to fill in <span class="s5">the details requested via </span>a set of prede<span class="s5">termined</span> and non-editable <span class="s5">entries</span> accessed <span class="s5">through </span>a dropdown style box<span class="s5"> (“</span><span class="s6">Inputs</span><span class="s5">”) as follows:</span></li>
                    </ul>
                    <p class="p8"><br/></p>
                    <ul class="ul1">
                      <li class="li3">Sender Name;</li>
                    </ul>
                    <p class="p7"><br/></p>
                    <ul class="ul1">
                      <li class="li3">Receiver Name;</li>
                    </ul>
                    <p class="p7"><br/></p>
                    <ul class="ul1">
                      <li class="li3">Request for the relation between Sender and Receiver;</li>
                    </ul>
                    <p class="p7"><br/></p>
                    <ul class="ul1">
                      <li class="li3">Request the type of music for the Playlist;<span class="Apple-converted-space"> </span></li>
                    </ul>
                    <p class="p7"><br/></p>
                    <ul class="ul1">
                      <li class="li3">Request the preferred language for a Playlist;</li>
                    </ul>
                    <p class="p7"><br/></p>
                    <ul class="ul1">
                      <li class="li3">Select a message in audio format which the Sender wants to dedicate to the Receiver.</li>
                    </ul>
                    <p class="p7"><br/></p>
                    <ul class="ul1">
                      <li class="li3">Vocal tone (Male or Female) in which the Sender wants to share the message with the Receiver.<span class="Apple-converted-space"> </span></li>
                    </ul>
                    <p class="p2"><br/></p>
                    <ul class="ul1">
                      <li class="li3">All Inputs provided by the Sender will be used to generate a Playlist and a message in audio format (“<b>Dedication</b>”).<span class="Apple-converted-space"> </span></li>
                    </ul>
                    <p class="p2"><br/></p>
                    <ul class="ul1">
                      <li class="li3">Unless the Sender provides all the Inputs as required under <span class="s1">Clause 3</span>, the Dedication will not be created.<span class="Apple-converted-space"> </span></li>
                    </ul>
                    <p class="p8"><br/></p>
                    <ul class="ul1">
                      <li class="li3">If a <span class="s5">Playlist generated </span>via the Inputs provided is not to a Sender’s preference, such Sender may click on the “Create a new playlist” button and provide a new set of Inputs as per Clause 3, <span class="s5">until </span>the Sender generates a Playlist which is to their preference and liking<span class="s5">.</span></li>
                    </ul>
                    <p class="p9"><br/></p>
                    <ul class="ul1">
                      <li class="li3">The Sender will then have the option to share the Playlist which has been generated with a Receiver of their choice in the following manner:<span class="Apple-converted-space"> </span></li>
                    </ul>
                    <p class="p2"><br/></p>
                    <p class="p3"><span class="Apple-tab-span"> </span><span class="Apple-converted-space">        </span>(i) <span class="s1"><b>Desktop Browser</b></span></p>
                    <p class="p2"><span class="s1"><b></b></span><br/></p>
                    <ul class="ul1">
                      <ul class="ul1">
                        <li class="li3">If the Sender chooses to share the Playlist through the Platform on a desktop, they may click on the “Share your Playlist” button on the Platform;</li>
                      </ul>
                    </ul>
                    <ul class="ul1">
                      <ul class="ul1">
                        <li class="li3">Once the Sender clicks on the “Share your Playlist” button, a link will be generated and the link will be copied on the clipboard;</li>
                      </ul>
                    </ul>
                    <ul class="ul1">
                      <ul class="ul1">
                        <li class="li3">Once the Link is copied on the clipboard, this link can now be shared by the Sender with a Receiver of their choice through Whatsapp, FB messenger, any other social media messaging applications/platforms;<span class="Apple-converted-space"> </span></li>
                      </ul>
                    </ul>
                    <p class="p6 u-margin-vertical">OR</p>
                    <p class="p3"><span class="Apple-tab-span"> </span><span class="Apple-converted-space">        </span>(ii) <span class="s1"><b>Mobile Browser</b></span></p>
                    <p class="p7"><br/></p>
                    <ul class="ul1">
                        <ul className="ul1">
                              <li class="li3">If the Participant chooses to share the Playlist through the Platform on the mobile browser, the Sender may click on the “Share your Playlist” button on the Platform;</li>
                        </ul>
                    </ul>
                    <p class="p8"><br/></p>
                    <ul class="ul1">
                        <ul className="ul1">
                          <li class="li3">Once the Sender clicks on the “Share your Playlist” button, a list of applications (Whatsapp, FB messenger, any other social media messaging applications/platforms) that allow link sharing, and are installed on the Sender’s device, will be automatically populated;</li>
                        </ul>
                    </ul>
                    <p class="p8"><br/></p>
                    <ul class="ul1">
                        <ul className="ul1">
                      <li class="li3">The Sender can then choose an application from the populated list and will have the option to share the link with a Receiver of their choice through any such application. <span class="Apple-converted-space"> </span></li>
                      </ul>
                    </ul>
                    <p class="p8"><br/></p>
                    <ul class="ul1">
                                                <ul className="ul1">

                      <li class="li3">Once the Sender shares the link with t<span class="s5">he Receiver</span>, the Receiver may choose to access t<span class="s5">he </span>L<span class="s5">ink </span>which will take the Receiver on the Platform and:<span class="s5"><span class="Apple-converted-space"> </span></span></li>
                      </ul>
                    </ul>
                    <p class="p9"><br/></p>
                    <ul class="ul1">
                                            <ul className="ul1">

                      <li class="li3">Present the Dedication from the Sender to the Receiver; <i>and</i></li>
                      </ul>
                    </ul>
                    <p class="p12"><i></i><br/></p>
                    <p class="p13">B.<span class="Apple-converted-space">  </span>Provide the Receiver the option to listen to the Audio Message from the Sender; <i>and</i><span class="Apple-converted-space"> </span></p>
                    <p class="p12"><br/></p>
                    <p class="p13">C.<span class="Apple-converted-space">  </span>Provide the Receiver with the option to stream/play the songs from the Playlist as shared by the Sender only on the Platform.</p>
                    <p class="p2"><br/></p>
                    <ul class="ul1">
                                            <ul className="ul1">

                      <li class="li3">If the Receiver so chooses to, they may also choose to provide the Inputs as required under Clause 3 and create a Dedication of their preference in the manner provided in this section.<span class="Apple-converted-space"> </span></li>
                      </ul>
                    </ul>
                    <p class="p2"><br/></p>
                    <p class="p2"><br/></p>
                    <p class="p14"><span class="s5"><b>C.<span class="Apple-converted-space">    </span></b></span><b>GENERAL TERMS</b><span class="Apple-converted-space"> </span></p>
                    <p class="p5"><br/></p>
                    <ul class="ul1">
                      <li class="li3">The Participant must be a person over the age of 18 as of the start date of the Campaign.</li>
                    </ul>
                    <p class="p2"><span class="Apple-converted-space">  </span></p>
                    <ul class="ul1">
                      <li class="li3">All information provided by the Participant must be actual, true and accurate. If the Participant provides any false information in any manner whatsoever, JioSaavn will have the right to bar the Participant from this Campaign.<span class="Apple-converted-space"> </span></li>
                    </ul>
                    <p class="p2"><br/></p>
                    <ul class="ul1">
                      <li class="li3">The Participant agrees that the use of the Platform and the Campaign shall be for the purpose as provided in these Rules and not maliciously and with an intent to harass any person in any manner whatsoever.</li>
                    </ul>
                    <p class="p12"><br/></p>
                    <ul class="ul1">
                      <li class="li3">The Participant will ensure that the Dedication or any part thereof do not:</li>
                    </ul>
                    <p class="p7"><br/></p>
                    <ul class="ul1">
                      <ul class="ul1">
                        <li class="li3">Contain any combination of inputs that may be construed as obscene, derogatory, intimidating, inappropriate or harassing; <i>and</i></li>
                        <li class="li3">​Shall <span class="s1">not</span> violate the rights of any third party, including, but not limited to, privacy rights, copyrights, trademark rights and/or any other intellectual property rights; <i>and</i><span class="Apple-converted-space">                                 <span class="Apple-tab-span">   </span></span></li>
                        <li class="li3">Shall <span class="s1">not</span> contain any content deemed to be abusive, racially, ethnically objectionable, disparaging, harmful, blasphemous, defamatory, obscene, libellous, or hateful towards any class of citizens, the National Flag of India and the Constitution of India; <i>and</i></li>
                        <li class="li3">Shall <span class="s1">not</span> contain any content deemed to be relating to or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; <i>and</i></li>
                        <li class="li15">Does <span class="s1">not</span> harm minors in any way; <i>and</i></li>
                        <li class="li15">Does <span class="s1">not</span> violate any law for the time being in force; <i>and</i></li>
                        <li class="li15">Does <span class="s1">not</span> deceive or mislead about the origin of such messages or communicates any information which is grossly offensive or menacing in nature; <i>and</i></li>
                        <li class="li3">Does <span class="s1">not</span> contain software viruses or any other computer code, files or programs designed to interrupt, destroy, or limit the functionality of any computer resource.</li>
                      </ul>
                    </ul>
                    <p class="p12"><span class="Apple-converted-space"> </span></p>
                    <ul class="ul1">
                      <li class="li3">Participants shall not post abusive comments on JioSaavn, Center fresh and the Campaign’s social media pages and channels. In the event of any such profanity or abusive comments posted on the foregoing pages, JioSaavn reserves its rights, in addition to removing such posts, to take any legal action against such Participant including the right to claim damages.</li>
                    </ul>
                    <p class="p7"><br/></p>
                    <ul class="ul1">
                      <li class="li3">The Participant agrees that he/she shall not hold JioSaavn and/or their employees, Center fresh or their employees, responsible for delays by reason of “<b>Force Majeure</b>” such as acts of God, explosion, labour dispute, failure of utilities, governmental interferences, state of war, pandemics or any other act beyond the reasonable control in connection to this Campaign.</li>
                    </ul>
                    <p class="p2"><br/></p>
                    <ul class="ul1">
                      <li class="li3"><b>Intellectual Property Rights</b>. Nothing contained in these Rules constitutes a licence in favour of the Participant to the Songs and/or Playlists or use the intellectual property owned by JioSaavn and Center fresh such as trademarks, service marks or logos save and except for the purposes limited under this Campaign, and any use of the same shall be permitted only as per these Rules.</li>
                    </ul>
                    <p class="p2"><br/></p>
                    <ul class="ul1">
                      <li class="li3">The Campaign is limited to the Republic of India. All federal, state and local laws and regulations apply. Subject to any government approval that may be required, JioSaavn reserves the right to, without prior notice and at any time, terminate the Campaign in whole or in part, or modify the Campaign in any way, should any factor interfere with its proper conduct as contemplated by these Rules or any other breach to the Rules.</li>
                    </ul>
                    <p class="p2"><span class="Apple-converted-space"> </span></p>
                    <ul class="ul1">
                      <li class="li3"><b>Indemnification.</b> You agree to indemnify and hold harmless JioSaavn and Center fresh from all liability resulting from or arising from the Campaign, and hereby acknowledge that JioSaavn and Center fresh have neither made nor is in any manner responsible or liable for any warranty, representation or guarantee, express or implied, in fact, or in law, relating to the Campaign.</li>
                    </ul>
                    <p class="p2"><br/></p>
                    <ul class="ul1">
                      <li class="li3"><b>Limitation on Liability</b>.<span class="Apple-converted-space">  </span>JIOSAAVN OR CENTER FRESH WILL<span class="Apple-converted-space">  </span>NOT<span class="Apple-converted-space">  </span>BE<span class="Apple-converted-space">  </span>LIABLE<span class="Apple-converted-space">  </span>TO YOU FOR<span class="Apple-converted-space">  </span>DIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL PUNITIVE OR OTHER DAMAGES WITH RESPECT TO<span class="Apple-converted-space">  </span>THE CAMPAIGN OR<span class="Apple-converted-space">  </span>OTHERWISE<span class="Apple-converted-space">  </span>RELATED<span class="Apple-converted-space">  </span>TO JIOSAAVN’S<span class="Apple-converted-space">  </span>PERFORMANCE<span class="Apple-converted-space">  </span>IN CONNECTION WITH THIS CAMPAIGN, INCLUDING BUT NOT LIMITED TO LOSS OF DATA AND CONTENT WITHIN THE DEDICATION, OF WHETHER<span class="Apple-converted-space">  </span>THE<span class="Apple-converted-space">  </span>CAUSE<span class="Apple-converted-space">  </span>OF<span class="Apple-converted-space">  </span>ACTION<span class="Apple-converted-space">  </span>IS<span class="Apple-converted-space">  </span>BASED<span class="Apple-converted-space">  </span>ON CONTRACT, TORT, STRICT LIABILITY OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT<span class="Apple-converted-space">  </span>SUCH<span class="Apple-converted-space">  </span>DAMAGES<span class="Apple-converted-space">  </span>WERE<span class="Apple-converted-space">  </span>FORESEEABLE<span class="Apple-converted-space">  </span>AND<span class="Apple-converted-space">  </span>EVEN<span class="Apple-converted-space">  </span>IF THE<span class="Apple-converted-space">  </span>COMPANY HAS BEEN ADVISED THAT SUCH DAMAGES ARE LIKELY OR POSSIBLE.</li>
                    </ul>
                    <p class="p2"><br/></p>
                    <ul class="ul1">
                      <li class="li3">JioSaavn reserves the right to suspend or cancel the Campaign at any time at its sole and exclusive discretion.</li>
                    </ul>
                    <p class="p2"><br/></p>
                    <ul class="ul1">
                      <li class="li3">JioSaavn may revise these Rules at any time at its sole discretion by posting an updated version of the Rules. Any Rules changes will be effective upon posting on the Platform and therefore, Participants are advised to regularly check for the updated Rules and no correspondence in this regard will be entertained. Your continued participation in the Campaign after a change to these Rules constitutes your binding acceptance of these Rules.</li>
                    </ul>
                    <p class="p2"><br/></p>
                    <ul class="ul1">
                      <li class="li3"><b>Jurisdiction and Choice of Law.<span class="Apple-converted-space"> </span></b> All issues and questions concerning the construction, validity, interpretation and enforceability of the Rules, or the rights and obligations of The Participant and JioSaavn in connection with the Campaign, will be governed by, and construed in accordance with, the substantive laws of India. The exclusive jurisdiction and venue of any action arising out of or related to these Rules will be the Courts in Mumbai, and you agree and submit to the personal and exclusive jurisdiction and venue of these courts.</li>
                    </ul>

                </div>
                </div>
            </section>
        </main>
    );

}

export default Terms;




