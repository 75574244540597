/**
 * Imports
 */
import React, { useEffect, useMemo, useState } from 'react';

import Select from '../../../Select/components/Container/Select'

import usePrevious from '../../../../_hooks/usePrevious';
import { convertToDateObj, getDays, isEmpty, isDeepEqual } from '../../../../utils';





/**
 * Form field view
 *
 * @param {object} props
 */
const Field = (props) => {

    //REMOVE THIS
    const axios = {};

    /**
     * Properties
     */
    const { form, ...attributes } = props;
    const [field, setField] = useState({ ...attributes, isChecking: false });
    const { isChecking, className, disabled, error, label, max, min, multiple, name, options, pattern, placeholder, required, type, valid, value, isSearch, handleSetValue } = field;
    const prevAttrs = usePrevious(attributes);

    /**
     * Handle value update
     *
     * @param {string} value
     */
    const handleChange = (value) => {
        const current = Object.assign({}, field, { error: '' });

        if (type === 'check') current.valid = false;

        if (type === 'date') {
            if (isEmpty(value)) current.value = [];
            if (parseInt(value.length) > 0) {
                current.value = value;
            } else {
                if (field.value.length > 1) current.value = [value];
                if (field.value.length < 2) current.value = field.value.length ? field.value.concat(value) : [value];
            }
        } else {
            if (multiple || type === 'checkbox') {
                if (field.value.includes(value) || field.value === value) {
                    let index = field.value.indexOf(value);
                    current.value.splice(index, 1);
                } else {
                    if (isSearch) {
                        current.value = [value];
                    } else {
                        current.value.push(value);
                    }
                }
            } else {
                current.value = typeof field.value === 'object' ? [value] : value;
            }
        }

        if (required && !value) current.error = 'Field is required';
        if (value) {
            if (pattern && !(new RegExp(pattern, 'ig')).test(value)) {
                if (type === 'number') current.error = 'Use numbers only';
                if (name === 'prefix') current.error = 'Use letters only';
                if (name === 'segid') current.error = 'Use letters and numbers only';
            }
        }
        handleSetValue(current.value, current.name);
        return setField(current);
    };

    /**
     * Handle check of availability
     */
    const handleCheck = async () => {
        if (field.valid || (field.error && field.error !== 'Must be validated')) return;
        if (!value) return setField(prevField => ({ ...prevField, error: 'Field is required' }));

        setField(prevField => ({ ...prevField, isChecking: true, error: '' }));

        const params = { '__call': 'couponCodeTool.checkValidity', [name]: value, 'api_version': 4, '_format': 'json', '_marker': 0 };
        const response = await axios.get(`${process.env.API_URL}/api.php`, { params });

        if (response.status !== 200
            || response.statusText !== 'OK'
            || response.data.status !== 'success') return setField(prevField => ({ ...prevField, isChecking: false, valid: false }));

        if (response.data.data) return setField(prevField => ({ ...prevField, isChecking: false, error: 'Already in use', valid: false }));

        return setField(prevField => ({ ...prevField, isChecking: false, valid: true }));
    };

    /**
     * Set class names
     */
    const setClassList = () => {
        const classList = ['field'];

        if (className) classList.push(className);
        if (disabled || isChecking) classList.push('disabled');
        if (error) classList.push('error');

        return classList.join(' ');
    };

    /**
     * On component update
     */
    useEffect(() => {
        if (!prevAttrs || isDeepEqual(attributes, prevAttrs)) return;

        setField({ ...attributes, isChecking: false });
    }, [attributes]);

    /**
     * Set input based on type
     */
    const renderInput = () => {
        switch (type) {
            case 'checkbox':
            case 'radio':
                return (
                    <>
                        <ul className="o-list-bare u-margin-bottom-none">
                        {options.map(option => (
                            <li key={`${name}-${option.value}`}>
                                <input type={type} id={`${name}-${option.value}`} name={`${name}-visual`} value={option.value} checked={value.includes(option.value)} onChange={(e) => handleChange(e.target.value)} />
                                <label htmlFor={`${name}-${option.value}`}>{option.label}</label>
                            </li>
                        ))}
                        </ul>
                        <input type="hidden" name={name} value={type === 'checkbox' ? JSON.stringify(value) : value} />
                    </>
                );

            case 'check':
                return (
                    <>
                        <div className="input-icon">
                            <div className="input-icon__indicator">
                                {isChecking ? <div className="c-loader c-loader--small"></div> : <span className={`o-icon-check o-icon--${valid ? 'circle' : 'circle-alt'}`}></span>}
                            </div>

                            <input
                                id={name}
                                name={name}
                                placeholder={placeholder || ''}
                                type="text"
                                value={value}
                                onChange={(e) => handleChange(e.target.value)} />

                            <input type="hidden" id={`${name}-valid`} name={`${name}-valid`} value={valid ? 'true' : 'false'} />
                        </div>
                        <p onClick={handleCheck} className={`u-color-js-green u-padding-vertical-tiny u-margin-bottom-none${valid ? ' disabled' : ' u-pointer'}`}><strong>Check Availability</strong></p>
                    </>
                );

            case 'select':
                return (
                    <Select
                        className="u-margin-bottom-small"
                        isAuto={true}
                        id={name}
                        multiple={multiple || false}
                        name={name}
                        options={options}
                        placeholder={placeholder || ''}
                        isSearch={props.isSearch}
                        isSearching={props.isSearching}
                        handleSearch={props.handleSearch}
                        selected={value}
                        handleSelection={({ value }) => handleChange(value)} />
                );

            default:
                return (
                    <input
                        id={name}
                        name={name}
                        placeholder={placeholder || ''}
                        type={type === 'text' || type ==='number' ? 'text' : type}
                        value={value}
                        onChange={(e) => handleChange(e.target.value)} />
                );
        }

    };

    /**
     * Component rendering
     */
    return (
        <div className={setClassList()}>
            <label htmlFor={name}>{label}</label>
            {renderInput()}
            <span className={`field__msg${error ? ' active' : ''}`}>{<>&nbsp;</>}</span>
        </div>
    );

};





/**
 * Default export
 */
export default React.memo(Field);




