/**
 * Imports
 */
import React, { useEffect, useState, useRef } from 'react';

import SelectView from '../Presentational/Select';

import { isEqual } from '../../../../utils';





/**
 * Select options
 *
 * @param {object} props
 */
const Select = (props) => {

    /**
     * Properties
     */
    const { handleSelection, isAuto, isFlipped, className, disabled, error, id, icon, multiple, max, min, name, options, placeholder, selected, isSearch, isSearching } = props;
    const [state, setState] = useState({ filtered: options || [], open: false, term: '' });

    // use Ref to access state in event handler
    const stateRef = useRef(state);

    /**
     * Set class list
     */
    const setClassList = () => {
        const classList = ['c-select'];

        if (isAuto) classList.push('c-select--auto');
        if (isFlipped) classList.push('c-select--left');
        if (multiple || isSearch) classList.push('c-select--multiple');
        if (!icon) classList.push('c-select--no-icon');

        if (state.open) classList.push('active');

        if (disabled) classList.push('disabled');
        if (error && !disabled) classList.push('error');

        if (className) classList.push(className);

        return classList.join(' ');
    };

    /**
     * Handle option filtering
     *
     * @param {string} value
     */
    const handleFilter = (value) => {
        const text = value.toLowerCase();
        var filtered;
        if (isSearch) {
            props.handleSearch(value, name);
            return setState(prevState => ({ ...prevState, term: value }));
        } else {
            filtered = options.filter(opt => opt.label.toLowerCase().includes(text) || opt.value.toLowerCase().includes(text));
            return setState(prevState => ({ ...prevState, filtered, term: value }));
        }
    };

    const handleSetTerm = (value) => {
        window.setTimeout(() => {
            setState(prevState => ({...prevState, term: value, open: false}))
        })
    }

    useEffect(() => {
        stateRef.current = state;
    }, [state])

    /**
     * On hide/show toggle
     */
    useEffect(() => {
        const handleExternalClick = (e) => {
            const selectInput = e.target.closest('input');
            const selectList = e.target.closest('.c-select__list');
            const parentEl = e.target.closest('.c-select') && e.target.closest('.c-');
            const isSameInput = selectInput && selectInput.name.includes(name);
            const elementExists = document.body.contains(e.target);

            if ((parentEl || selectInput || !elementExists) && isSameInput ) return;
            if (state.open && isSearch) {
                var filtered = stateRef.current.filtered.filter((opt) => opt.label === stateRef.current.term );
                if (filtered && filtered.length === 1) {
                    handleSelection(filtered[0])
                    return setState(prevState => ({ ...prevState, open: false, term: stateRef.current.term }));
                } else {
                    return setState(prevState => ({ ...prevState, open: false, term: '' }));
                }
            }
            if (state.open && !(multiple) && !(isSearch)) return setState(prevState => ({ ...prevState, open: false }));
            if (state.open && !selectList) return setState(prevState => ({ ...prevState, open: false }));
        };

        window.addEventListener('click', handleExternalClick);
        return () => window.removeEventListener('click', handleExternalClick);
    }, [state.open]);

    /**
     * On selection update
     */
    useEffect(() => {
        if (!multiple && selected.length) setState(prevState => ({ ...prevState, open: false }));
    }, [selected]);

    /**
     * Component rendering
     */
    return (
        <SelectView
            className={setClassList()}
            handleClick={() => (setState(prevState => ({ ...prevState, open: !prevState.open })))}
            handleFilter={handleFilter}
            handleOnFocus={() => setState(prevState => ({ ...prevState, open: !prevState.open }))}
            handleSelection={handleSelection}
            handleSetTerm={handleSetTerm}
            list={isSearch ? options : state.filtered}
            id={id}
            icon={icon}
            isSearch={isSearch}
            isSearching={isSearching}
            multiple={multiple}
            max={max}
            min={min}
            name={name}
            options={options}
            placeholder={placeholder}
            selected={selected}
            term={state.term} />
    );
}





/**
 * Default export
 */
export default React.memo(Select);




