import React from 'react';
import { hydrate } from 'react-dom';
import './scss/styles.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { legacy_createStore as createStore } from 'redux';
import { Provider } from 'react-redux';
import reducer from './rootReducer'

//console.log("client side preloadedstate",window.__PRELOADED_STATE__);

const store = createStore(reducer, window.__PRELOADED_STATE__);
delete window.__PRELOADED_STATE__

//console.log("client side store state ",store.getState());

hydrate(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
