
import React, {useState, useRef} from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { formatBotSrcEventData, logEvent } from "./events/eventsUtils";

import Home from "./pages/Home/components/container/Home";
import Faq from "./pages/Faq/components/presentational/Faq";
import Terms from "./pages/Terms/components/presentational/Terms";
import Header from './components/Header/components/presentational/Header'
import Footer from './components/Footer/components/presentational/Footer'
import Modal from './components/Modal/components/presentational/Modal'
import Toast from './components/Toast/components/presentational/Toast'
import Sidebar from './components/Sidebar/components/presentational/Sidebar'
import Playlist from './components/Playlist/components/Presentational/Playlist'

const App = (props) => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [toast, setToast] = useState(false);
    const scrollPos = useRef();

    let location = useLocation();
    const isReceiverPage = location.pathname.includes('/receiver/') ? true : false;

    const toggleModal = () => {
        if (!modalOpen) {
            if (window) {
                scrollPos.current = window.scrollY
                document.body.classList.add('no-scroll');
                document.body.scrollTop = scrollPos.current;
            }
        } else {
            document.body.classList.remove('no-scroll');
            document.body.scrollTop = scrollPos.current;
        }
        setModalOpen(!modalOpen);

        if(!isReceiverPage && !modalOpen){
            const view_event_data = formatBotSrcEventData({
                event_type : "view",
                bot_src: {
                    screen_name: "centerfresh_playlist_screen"
                }
            });
            logEvent(view_event_data);
        }
    }

    const toggleToast = () => {
        if (!toast && window) {
            setToast(true);
            window.setTimeout(function(){
                setToast(false);
            }, 5000)
        }
    }

    const toggleSidebar = () => {
        if (!sidebarOpen) {
            if (window) {
                scrollPos.current = window.scrollY
                document.body.classList.add('no-scroll');
                document.body.scrollTop = scrollPos.current;
            }
        } else {
            document.body.classList.remove('no-scroll');
            document.body.scrollTop = scrollPos.current;
        }
        setSidebarOpen(!sidebarOpen);
    }

     return (
        <>
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={toggleSidebar} isReceiverPage={isReceiverPage} />
            <Header setSidebarOpen={toggleSidebar} sidebarOpen={sidebarOpen} isReceiverPage={isReceiverPage} />
                <Routes>
                    <Route exact path="/" element={<Home toggleModal={toggleModal} isReceiverPage={isReceiverPage} />} />
                    <Route exact path="/faq" element={<Faq />} />
                    <Route exact path="/terms" element={<Terms />} />
                    <Route path="/receiver/:id" element={<Home toggleModal={toggleModal} isReceiverPage={isReceiverPage} />} />
                </Routes>
            <Footer isReceiverPage={isReceiverPage} />
            {modalOpen ? 
                <Modal modalOpen={modalOpen} toggleModal={toggleModal}>
                    <Playlist toggleModal={toggleModal} toggleToast={toggleToast} isReceiverPage={isReceiverPage}/>
                </Modal>
                : ''
            }
            <Toast isActive={toast} />
        </>
    );  
  
}

export default App;