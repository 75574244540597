// Reference for creating the player: https://www.youtube.com/watch?v=sqpg1qzJCGQ

/**
 * Imports
 */
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getSongAuthUrl, getShortenedURL } from '../../../../api_handler';
import { Link } from "react-router-dom";
import { formatBotSrcEventData, logEvent } from '../../../../events/eventsUtils';
import Player from '../../../Player/container/Player'
import Logo from '../../../Helpers/components/presentational/Logo';
import {truncate} from '../../../../utils';


/**
 * Home page view
 */
const Playlist = ({fields, modalOpen, toggleModal, toggleToast, isInline, isReceiverPage}) => {

    const playlistData = useSelector(state => state.homePageView);  

    const { songs, sender, receiver, message, voice, token, title, playlist_id } = playlistData;
    var totalDuration = 0;
    songs.map(song => totalDuration += Number(song.duration));

    // States
    const [share, setShare] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isFirstSong, setIsFirstSong] = useState(true);
    const [playbackPos, setPlaybackPos] = useState(0);
    const [playingSong, setPlayingSong] = useState(0);
    const [queueState, setQueueState] = useState(0);
    const [volume, setVolume] = useState(true);
    const [isReady, setIsReady] = useState(true);

    
    // Refs
    const queueNumber = useRef(0);
    const mainPlayer = useRef();
    const animationRef = useRef();
    const lastUpdatedTimeRef = useRef(0);
    const startTimeRef = useRef(0);
    const seekTime = useRef(0);


    const handlePlaylistClickEvent = entity => {
        const event_data = formatBotSrcEventData({
            event_type: "click",
            bot_src: {
                screen_name: isReceiverPage ? "centerfresh_receiver_screen" : "centerfresh_playlist_screen",
                sec_title: 'playlist',
                entity_name: entity,
                entity_id: entity.replace(/\s+/g, '_').toLowerCase(),
                entity_type: "button",
            }
        });
        logEvent(event_data);
    };

    const handlePlayerProgressEvent = time => {
        const event_data = formatBotSrcEventData({
            event_type: "player:progress:" + String(time),
            bot_src: {
                screen_name: isReceiverPage ? "centerfresh_receiver_screen" : "centerfresh_sender_screen",
                sec_title: 'playlist',
                entity_name: songs[queueNumber.current]['title'],
                entity_id: songs[queueNumber.current]['id'],
                stream_entity_id: songs[queueNumber.current]['id'],
                entity_type: "song",
            }
        });
        event_data.songid = songs[queueNumber.current]['id'];
        logEvent(event_data);
    }


    const handlePlayerMediaEvent = (ev) => {
        const event_data = formatBotSrcEventData({
            event_type: "player:" + ev,
            bot_src: {
                screen_name: isReceiverPage ? "centerfresh_receiver_screen" : "centerfresh_sender_screen",
                entity_name: songs[queueNumber.current]['title'],
                entity_id: songs[queueNumber.current]['id'],
                entity_type: 'song',
                stream_entity_name: title,
                stream_entity_type: 'playlist',
                stream_entity_id: playlist_id
            } 
        });
        if(ev == "mediaunload" || ev == "mediapaused"){
            let ts = Date.now();
            let timePlayed = Math.floor(mainPlayer.current.currentTime);

            event_data.player_mode = 'centerfresh_microsite';
            event_data.bitrate = 128;
            event_data.songid = songs[queueNumber.current]['id'];
            event_data.ts = ts;
            event_data.initial_buffer_time = 0;
            event_data.total_buffer_time = 0;
            event_data.total_playtime = (timePlayed - seekTime.current) * 1000;
            event_data.start_time = startTimeRef.current;
            event_data.end_pos = timePlayed;
        }
        logEvent(event_data);
    }
    

    const handleShare = async (e) => {
        var shortenedURL = await getShortenedURL(token);
        handlePlaylistClickEvent('Share Button');
        if (navigator.share) {
            navigator.share({url: 'https://' + shortenedURL, title: 'Center fresh Love Songs', text: 'Hey! Someone special has gifted you Center fresh Love songs! 👀❤️'});
        } else {
            copy(e, shortenedURL);
        }
    }

    let input = undefined;

    /**
     * Check if IOS
     */
    const isIOS = () => {
        return (navigator && navigator.userAgent.match(/ipad|iphone/i));
    }

    /**
     * Select the text from input
     */
    const selectText = () =>  {
        let range, selection;

        if (isIOS()) {
            range = document.createRange();
            selection = window.getSelection();

            range.selectNodeContents(input);
            selection.removeAllRanges();
            selection.addRange(range);
            input.setSelectionRange(0,99999);
        } else {
            input.select();
        }
    };

    /**
     * Copy to the clipboard
     */
    const copyTextToClipboard = () => {
        const success = document.execCommand('copy');

        if (success) {
            toggleToast();
        } else {
            //fail
        }

        document.body.removeChild(input);
    };

    /**
     * Create input for copying
     */
    const createTextArea = (url) => {
        input = document.createElement('textArea');
        input.disabled = isIOS();
        input.value = url;

        document.body.appendChild(input);
    }

    const copy = (e, url) => {
        e.preventDefault();
        //console.log("HERE IN COPY")
        createTextArea(url);
        selectText();
        copyTextToClipboard();
    }

    

    // async function to call the API Handler
    const fetchURL = async () => {
        lastUpdatedTimeRef.current = 0;
        handlePlayerMediaEvent("mediastarted",queueNumber.current);
        let playable_url = await getSongAuthUrl(songs[queueNumber.current]['encrypted_media_url']);
        mainPlayer.current.src = playable_url;
        setPlaybackPos(0);
        mainPlayer.current.play();
        startTimeRef.current = Date.now();
        handlePlayerMediaEvent("mediaopen",queueNumber.current);
        seekTime.current = 0;
        return;
    }


    //Handler for any click on the song
    const handleSongClick = async (index) => {
        handlePlaylistClickEvent('Song');
        if(!mainPlayer.current.paused){
            handlePlayerMediaEvent("mediaend",queueNumber.current);
            handlePlayerMediaEvent("mediaunload",queueNumber.current);
        }
        queueNumber.current = index;
        setQueueState(index);
        await fetchURL();
        setIsFirstSong(false);
        setIsPlaying(true);
        start();
    }


    // Handler for the button 'Play Songs'
    const handlePlaySongsButton = async () => {
        handlePlaylistClickEvent('Play Songs Button');
        if(!mainPlayer.current.paused){
            handlePlayerMediaEvent("mediaend",queueNumber.current);
            handlePlayerMediaEvent("mediaunload",queueNumber.current);
        }
        queueNumber.current = 0;
        setQueueState(0);
        await fetchURL();
        setIsPlaying(true);
        setIsFirstSong(false);
        start();
    }


    // Handler For Play/Pause Action of the Player
    const togglePlayPause = async () => {
        if (isPlaying) {
            mainPlayer.current.pause();
            handlePlayerMediaEvent("mediapaused",queueNumber.current);
            setIsPlaying(false);
            cancelAnimationFrame(animationRef.current);
        } else {
            // When the player loads for the first time, there is nothing to play. Hence fetching the URL.
            if(isFirstSong) { await fetchURL(); setIsFirstSong(false); }
            mainPlayer.current.play();
            setIsPlaying(true);
            animationRef.current = requestAnimationFrame(start);
        }
    };


    // Function for initializing the slider animation
    const start = () => {
        animationRef.current = requestAnimationFrame(start);
        
        if (mainPlayer.current.readyState) {
            setIsReady(true);
        } else {
            setIsReady(false);
        }

        if (mainPlayer.current.currentTime >= mainPlayer.current.duration) {
            cancelAnimationFrame(animationRef.current);
            handleNext();
        }
    };


    // Handler for previous song button
    const handlePrevious = async () => {
        handlePlaylistClickEvent('Previous Song Button');
        if(!mainPlayer.current.paused)   handlePlayerMediaEvent("mediaend",queueNumber.current);
        handlePlayerMediaEvent("play_previous",queueNumber.current);
        if(!mainPlayer.current.paused)   handlePlayerMediaEvent("mediaunload",queueNumber.current);
        queueNumber.current = (queueNumber.current - 1 + songs.length) % songs.length;
        setQueueState((prevState) => ((prevState - 1 < 0 ? songs.length - 1 : prevState - 1) % songs.length))
        await fetchURL();
        setPlaybackPos(0);
        if (isPlaying === false) togglePlayPause();
    };


    // Handler for next song button
    const handleNext = async () => {
        if( !mainPlayer.current.paused )   handlePlayerMediaEvent("mediaend",queueNumber.current);
        handlePlayerMediaEvent("play_next",queueNumber.current);
        if( !mainPlayer.current.paused )   handlePlayerMediaEvent("mediaunload",queueNumber.current);
        queueNumber.current = (queueNumber.current + 1) % songs.length;
        setQueueState((prevState) => ((prevState + 1) % songs.length))
        await fetchURL();
        setPlaybackPos(0);
        if (isPlaying === false) togglePlayPause();
    };


    const handleSeek = (e) => {
        handlePlaylistClickEvent('Seek');
        const rect = e.target.getBoundingClientRect();
        const width = e.target.offsetWidth;
        const x = e.clientX - rect.left; //x position within the element.
        const pos = x / width;
        seekTime.current += Math.floor((mainPlayer.current.duration * pos) - mainPlayer.current.currentTime);
        mainPlayer.current.currentTime = mainPlayer.current.duration * pos;
        setPlaybackPos(pos);
    };

    // Handler for the volume button
    const handleVolume = (event) => {
        handlePlaylistClickEvent('Volume Button');
        mainPlayer.current.muted = mainPlayer.current.muted ? false : true;
        setVolume((prevVolume) => !prevVolume);
    };

    const handleTimeUpdate = (e) => {
        let currentSeekTime = Math.floor(mainPlayer.current.currentTime);
        if(currentSeekTime%30 == 0 && currentSeekTime!=lastUpdatedTimeRef.current){
            lastUpdatedTimeRef.current = currentSeekTime;
            handlePlayerProgressEvent(currentSeekTime);
        }
        setPlaybackPos(Math.floor((mainPlayer.current.currentTime / mainPlayer.current.duration) * 100))
    }




    /**
     * Component view
     */
    return (
        <>
            {!isInline ? 
                <div className="c-modal__close">
                    <span className="o-pin o-pin--tiny u-background-gray-alt-dark u-cursor-pointer" onClick={() => {mainPlayer.current.pause(); handlePlaylistClickEvent('Close Button'); toggleModal();}}>
                        <span className="o-icon-close u-color-white"></span>
                    </span>
                </div> : ''
            }
            <div className="c-playlist">
                <div className="o-wrapper u-margin-horizontal-massive@md">
                    {isInline ? 
                        <div className="o-box u-background-dark-blue-alt u-padding u-border-radius u-margin-bottom-large">
                            <div className="o-layout o-layout--middle">
                                <div className="o-layout__item u-3/4@md u-margin-bottom-none@md u-margin-bottom">
                                    <div className="u-align-center u-align-left@md">
                                        <h5 className="u-margin-bottom">Hey {receiver}, {sender} has dedicated a special message for you.</h5>
                                        <p className='u-margin-bottom-none'>Dil Ki Baat Zubaan Pe!</p>
                                    </div>
                                </div>
                                <div className="o-layout__item u-1/4@md">
                                    <Player receiver={receiver} sender={sender} message={message} voice={voice} handlePlaylistClickEvent={handlePlaylistClickEvent} />
                                </div>
                            </div>
                        </div>    
                    : ''}

                    <div className="o-layout o-layout--center u-hidden@md">
                        <div className="o-layout__item u-3/5 u-align-center">
                            <div className="u-margin-bottom u-padding-bottom-small">
                                <img className="u-border-radius" src="https://c.saavncdn.com/editorial/NewLoveMixBengali_20221205130001.jpg" />
                            </div>
                            <h3 className="">{title}</h3>
                            <p className="u-margin-bottom-large u-color-js-green">by JioSaavn · {songs.length} Songs · {Math.floor(totalDuration/60)}:{String(totalDuration%60).padStart(2,0)}</p>
                        </div>
                    </div>

                    <div className="o-flag o-flag--large u-visible-visually@md u-margin-bottom-large">
                        <div className="o-flag__img u-1/3">
                            <img className="u-border-radius" src="https://c.saavncdn.com/editorial/NewLoveMixBengali_20221205130001.jpg" />
                        </div>    
                        <div className="o-flag__body">
                            <h3 className="u-margin-bottom-small">{title}</h3>
                            <p className="u-margin-bottom-large u-color-js-green">by JioSaavn · {songs.length} Songs · {Math.floor(totalDuration/60)}:{String(totalDuration%60).padStart(2,0)}</p>
                            <p className="u-color-white u-margin-bottom">{isInline ? `A Playlist Made Just for You by ${sender} ❤️🎶` : `Uff! Your playlist is soooo romantic! You can share it now. 🤞`}</p>
                            { isInline ? 
                                <>
                                    <div className="o-layout o-layout--auto o-layout--middle u-margin-bottom">
                                        <div className="o-layout__item u-margin-right-small u-margin-bottom-small u-margin-bottom-none@md">
                                            <button className="c-btn c-btn--primary c-btn--primary-alt" onClick={handlePlaySongsButton}><span className="o-icon-play u-margin-right"></span> Play Songs</button>
                                        </div>
                                        <div className="o-layout__item">
                                            <div className="u-centi">
                                                <a className="u-color-pink u-underline" href="/#playlist-form" target="_blank" onClick={() => { handlePlaylistClickEvent("Create a New Playlist") }}>Create your own playlist</a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="o-layout o-layout--auto o-layout--middle u-margin-bottom">
                                    <div className="o-layout__item u-margin-right-small">
                                        <button className="c-btn c-btn--primary c-btn--primary-alt u-margin-bottom-none" onClick={(e) => handleShare(e)}>
                                            <span className="u-margin-right">
                                                <svg className="fake-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20.003" viewBox="0 0 20 20.003">
                                                  <g id="Share" transform="translate(-67.5 -70.01)">
                                                    <path id="Shape_30" data-name="Shape 30" d="M1.282,20A1.275,1.275,0,0,1,0,18.735V10.618A1.275,1.275,0,0,1,1.282,9.349H3.59v0a.758.758,0,0,1,0,1.516v0H2.564a1.02,1.02,0,0,0-1.026,1.015v5.581a1.02,1.02,0,0,0,1.026,1.015H17.436a1.02,1.02,0,0,0,1.026-1.015V11.886a1.02,1.02,0,0,0-1.026-1.015H16.41v0a.757.757,0,0,1,0-1.513v0h2.308A1.275,1.275,0,0,1,20,10.618v8.117A1.275,1.275,0,0,1,18.718,20Zm7.949-5.327V2.562L5.436,6.316a.775.775,0,0,1-1.088,0,.755.755,0,0,1,0-1.076L9.425.218a.871.871,0,0,1,1.15,0L15.652,5.24a.755.755,0,0,1,0,1.076.775.775,0,0,1-1.088,0L10.769,2.562V14.676a.769.769,0,0,1-1.538,0Z" transform="translate(67.5 70.01)" fill="#00133c"/>
                                                  </g>
                                                </svg>
                                            </span>
                                            Share your playlist
                                        </button> 
                                    </div>
                                    <div className="o-layout__item">
                                        <div className="u-centi">
                                            <Link className="u-color-pink u-underline" to="#playlist-form" onClick={() => { handlePlaylistClickEvent("Create a New Playlist"); toggleModal(); }}>Create a new playlist</Link>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="u-align-center  u-margin-bottom u-hidden@md u-padding-horizontal u-padding-bottom">
                        <h4>{isInline ? `Here's a playlist made just for You!` : `Uff! Your playlist is soooo romantic! You can share it now. 🤞`}</h4>
                        { isInline ? 
                            <>
                            <button className="c-btn c-btn--primary c-btn--primary-alt u-margin-bottom-small" onClick={handlePlaySongsButton}><span className="o-icon-play u-margin-right"></span> Play Songs</button>
                            <div className="u-centi">
                                <a className="u-color-pink u-underline" href="/#playlist-form" target="_blank" onClick={() => { handlePlaylistClickEvent("Create a New Playlist") }}>Create your own playlist</a>
                            </div>
                            </>
                            : 
                            <>
                            <button className="c-btn c-btn--primary c-btn--primary-alt u-margin-bottom" onClick={(e) => handleShare(e)}>
                                <span className="u-margin-right">
                                    <svg className="fake-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20.003" viewBox="0 0 20 20.003">
                                      <g id="Share" transform="translate(-67.5 -70.01)">
                                        <path id="Shape_30" data-name="Shape 30" d="M1.282,20A1.275,1.275,0,0,1,0,18.735V10.618A1.275,1.275,0,0,1,1.282,9.349H3.59v0a.758.758,0,0,1,0,1.516v0H2.564a1.02,1.02,0,0,0-1.026,1.015v5.581a1.02,1.02,0,0,0,1.026,1.015H17.436a1.02,1.02,0,0,0,1.026-1.015V11.886a1.02,1.02,0,0,0-1.026-1.015H16.41v0a.757.757,0,0,1,0-1.513v0h2.308A1.275,1.275,0,0,1,20,10.618v8.117A1.275,1.275,0,0,1,18.718,20Zm7.949-5.327V2.562L5.436,6.316a.775.775,0,0,1-1.088,0,.755.755,0,0,1,0-1.076L9.425.218a.871.871,0,0,1,1.15,0L15.652,5.24a.755.755,0,0,1,0,1.076.775.775,0,0,1-1.088,0L10.769,2.562V14.676a.769.769,0,0,1-1.538,0Z" transform="translate(67.5 70.01)" fill="#00133c"/>
                                      </g>
                                    </svg>
                                </span>
                                Share your playlist
                            </button>
                            <div className="u-centi">
                                <a className="u-color-pink u-underline" href="#playlist-form" onClick={() => { handlePlaylistClickEvent("Create a New Playlist"); toggleModal(); }}>Create a new playlist</a>
                            </div>
                            </>
                        }
                    </div>
                    <hr className="o-rule u-color-js-gray-alt u-margin-bottom-large" />

                    <div className="c-player">
                        <p className="u-align-center u-margin-bottom-large">Your Playlist ❤️</p>
                        <audio
                            id="audio"
                            ref={mainPlayer}
                            index="0"
                            src=""
                            preload="metadata"
                            onTimeUpdate={handleTimeUpdate}
                        />
                        <div className="o-layout u-margin-bottom-large">
                            <div className="o-layout__item u-1/5"></div>
                            <div className="o-layout__item u-1/5 u-align-right" >
                                <span className="u-cursor-pointer" onClick={handlePrevious}>
                                    <svg className="fake-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.11 19.288" style={{transform: 'rotate(180deg)'}}>
                                      <path id="Next" d="M26.467,47.762A.864.864,0,0,1,25.6,46.9V29.774a.864.864,0,0,1,.864-.864h1.445a.864.864,0,0,1,.864.864V46.9a.864.864,0,0,1-.864.864H26.467Zm19.449.126a1.794,1.794,0,0,1-.9-.244L31.513,39.8a1.8,1.8,0,0,1,0-3.106l13.5-7.848a1.8,1.8,0,0,1,2.7,1.553v15.7a1.8,1.8,0,0,1-1.8,1.8Z" transform="translate(47.713 47.888) rotate(180)" fill="#fff" fillRule="evenodd"/>
                                    </svg>
                                </span>
                            </div>
                            <div className="o-layout__item u-1/5 u-align-center" >
                                { isReady ? 
                                    <span className={`o-icon-${isPlaying ? 'pause' : 'play'} u-cursor-pointer`} onClick={() => { togglePlayPause(); handlePlaylistClickEvent('Play Pause Button'); }}></span>
                                    : 
                                    <span className="c-loader"></span>
                                }
                            </div>
                            <div className="o-layout__item u-1/5 u-align-left">
                                <span className="u-cursor-pointer"  onClick={() => { handlePlaylistClickEvent('Next Song Button'); handleNext(); }}>
                                    <svg className="fake-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.11 19.288">
                                      <path id="Next" d="M26.467,47.762A.864.864,0,0,1,25.6,46.9V29.774a.864.864,0,0,1,.864-.864h1.445a.864.864,0,0,1,.864.864V46.9a.864.864,0,0,1-.864.864H26.467Zm19.449.126a1.794,1.794,0,0,1-.9-.244L31.513,39.8a1.8,1.8,0,0,1,0-3.106l13.5-7.848a1.8,1.8,0,0,1,2.7,1.553v15.7a1.8,1.8,0,0,1-1.8,1.8Z" transform="translate(47.713 47.888) rotate(180)" fill="#fff" fillRule="evenodd"/>
                                    </svg>
                                </span>
                            </div>
                            <div className="o-layout__item u-1/5" >
                                <span className={`o-icon-${volume ? 'volume' : 'mute'} u-cursor-pointer`} onClick={handleVolume}></span>
                            </div>
                        </div>
                        <div>
                            <div className="c-player__seek" onClick={handleSeek}>
                                <div className="c-player__seek-fill" style={{width: `${playbackPos}%` }}></div>
                                <div className="c-player__seek-head" style={{left: `${playbackPos}%` }}></div>
                            </div>
                        </div>
                    </div>
                    {songs && songs.map((song, i) => (
                        <div className={`o-flag u-margin-bottom-large u-cursor-pointer ${isPlaying && i === queueState ? 'u-color-js-green' : ''}`} onClick={() => handleSongClick(i)} key={song.id}>
                            <div className="o-flag__img">
                                {i + 1}
                            </div>
                            <div className="o-flag__body">
                                <div className="o-layout o-layout--center o-layout--middle">
                                    <div className="o-layout__item u-1/3@md u-1/2">
                                        <h6 className="u-margin-bottom-tiny" dangerouslySetInnerHTML={{__html: truncate(song.title, 44)}}></h6>
                                        <p className="u-margin-bottom-none">{truncate(song.subtitle, 44)}</p>
                                    </div>
                                    <div className="o-layout__item u-1/3 u-visible@md u-align-center">
                                        <p className={`u-margin-bottom-none ${isPlaying && i === queueState ? 'u-color-js-green' : ''}`} dangerouslySetInnerHTML={{__html: truncate(song.album, 44)}}></p>
                                    </div>
                                    <div className="o-layout__item u-1/3@md u-1/2 u-align-right">
                                        <p className={`u-margin-bottom-none ${isPlaying && i === queueState ? 'u-color-js-green' : ''}`}>{Math.floor(song.duration/60)}:{String(song.duration%60).padStart(2,0)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <hr className="o-rule u-color-js-gray-alt u-margin-bottom-large" />
                    <div className="u-align-center">
                        <a href="https://jiosaavn.com" target="_blank">
                        <span className="u-color-js-gray-alt-light u-margin-right-small" style={{fontSize: '13px'}}>Powered by</span>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 111 32" xmlSpace="preserve" style={{height: '20px', verticalAlign: 'middle'}}>
                            <path fill="#1ECCB0" d="M0,16c0,8.8,7.2,16,16,16c8.8,0,16-7.2,16-16c0-8.8-7.2-16-16-16C7.2,0,0,7.2,0,16z"/>
                            <path fill="#FFFFFF" d="M13.8,25.1c-0.6-1-1.4-1.9-2.4-2.5s-2-1.1-3.2-1.4H8.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.2c1.3,1.9,3.3,3.3,5.5,3.9h0.1c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.2C13.9,25.2,13.9,25.1,13.8,25.1L13.8,25.1z"/>
                            <path fill="#FFFFFF" d="M25.6,13.3c0,0,0-0.1,0-0.1c-0.5-1.8-1.5-3.4-2.9-4.6c-1.4-1.2-3-2.1-4.8-2.4h-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3v0.1c0.9,5.5,0.3,11.1-1.7,16.3c0,0.1-0.1,0.1-0.1,0.2s-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.2c0,0-0.1-0.7-0.1-1c-0.6-4.8-2-9.4-4.3-13.7c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1s-0.1,0-0.2,0C10.1,8,10.1,8,10,8l0,0c-1.4,1.1-2.5,2.5-3.2,4.2c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.2,0.2,0.2c2.2,1.4,4,3.2,5.3,5.5c1.3,2.2,2,4.8,2.1,7.3c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.2,0.1c0.2,0,0.5,0.1,0.7,0.1c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.1,0.1-0.2c0.5-2.6,1.6-5.1,3.3-7.2c1.7-2.1,3.8-3.7,6.3-4.8c0.1,0,0.1-0.1,0.2-0.1C25.6,13.4,25.6,13.4,25.6,13.3z"/>
                            <path fill="#FFFFFF" d="M24.7,20.9c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1h-0.1c-3.1,0.7-5.9,2.4-7.9,4.8c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1l0,0c1.6-0.1,3.2-0.6,4.6-1.4s2.6-2,3.4-3.4C24.7,21.1,24.7,21,24.7,20.9L24.7,20.9z"/>
                            <path fill="#FFFFFF" d="M40.3,22c-0.8,0-1.6-0.1-2.3-0.5c-0.6-0.3-1.1-0.8-1.5-1.4C36.2,19.4,36,18.7,36,18v-0.3h2.8c0,0.5,0.1,0.9,0.3,1.3c0.2,0.2,0.6,0.4,0.9,0.4s0.7,0,1-0.2c0.2-0.1,0.3-0.4,0.4-0.6c0.1-0.3,0.1-0.6,0.1-0.9V9.2h3.1v8.4c0.1,1.2-0.3,2.4-1.1,3.3c-0.4,0.4-1,0.7-1.5,0.9C41.4,21.9,40.9,22,40.3,22L40.3,22z"/>
                            <path fill="#FFFFFF" d="M47.7,11.1c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.6-0.5c0.3-0.1,0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.6,0.5c0.1,0.2,0.2,0.5,0.2,0.8c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.2-0.3,0.4-0.6,0.5C48.3,11,48,11.1,47.7,11.1L47.7,11.1z M46.3,21.7v-9.3h2.8v9.3H46.3z"/>
                            <path fill="#FFFFFF" d="M55.3,22c-0.9,0-1.8-0.2-2.5-0.6c-0.7-0.4-1.3-1-1.7-1.7c-0.4-0.8-0.6-1.7-0.6-2.6c0-0.9,0.2-1.8,0.6-2.6c0.4-0.7,0.9-1.3,1.7-1.7c0.8-0.4,1.7-0.6,2.5-0.6c0.9,0,1.7,0.2,2.5,0.6c0.7,0.4,1.3,1,1.6,1.7c0.4,0.8,0.6,1.7,0.6,2.6c0,0.9-0.2,1.8-0.6,2.6c-0.4,0.7-1,1.3-1.7,1.7C57,21.8,56.1,22,55.3,22z M55.3,19.9c0.4,0,0.8-0.1,1.1-0.3c0.3-0.3,0.6-0.6,0.7-1c0.2-0.5,0.2-1,0.2-1.5c0-0.5-0.1-1-0.2-1.4c-0.1-0.4-0.3-0.7-0.6-1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.6-0.7,0.9c-0.2,0.5-0.2,1-0.2,1.5c0,0.5,0.1,1,0.2,1.5c0.1,0.4,0.3,0.7,0.7,1C54.5,19.7,54.9,19.8,55.3,19.9L55.3,19.9z"/>
                            <path fill="#FFFFFF" d="M66.3,22c-0.9,0-1.8-0.1-2.6-0.4c-0.8-0.3-1.5-0.7-2-1.3c-0.5-0.6-0.8-1.4-0.8-2.1V18H64c0,0.3,0.1,0.7,0.3,0.9c0.2,0.3,0.5,0.4,0.9,0.5c0.4,0.1,0.8,0.2,1.2,0.2c0.5,0,1.1-0.1,1.5-0.3c0.2-0.1,0.3-0.2,0.5-0.4c0.1-0.2,0.2-0.4,0.1-0.6c0-0.2-0.1-0.5-0.2-0.6c-0.2-0.2-0.4-0.4-0.6-0.5c-0.4-0.2-0.8-0.3-1.2-0.4l-2.3-0.6c-0.6-0.1-1.1-0.4-1.6-0.8c-0.4-0.3-0.8-0.7-1-1.1c-0.2-0.5-0.3-1-0.3-1.5c0-0.8,0.2-1.5,0.7-2.1c0.5-0.6,1.1-1,1.8-1.3C64.5,9,65.4,8.9,66.2,9c0.9,0,1.7,0.1,2.5,0.4c0.7,0.2,1.3,0.7,1.7,1.2c0.4,0.6,0.7,1.3,0.7,2h-3c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.4-0.4c-0.5-0.2-1-0.4-1.5-0.3c-0.4,0-0.9,0.1-1.3,0.3c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.3,0.3,0.5,0.4c0.3,0.1,0.7,0.3,1,0.3l1.5,0.4c0.8,0.2,1.5,0.5,2.2,0.8c0.6,0.3,1,0.6,1.4,1.1c0.4,0.5,0.6,1.2,0.5,1.8c0,0.8-0.2,1.5-0.6,2.2c-0.5,0.6-1.1,1.1-1.8,1.4C68.2,21.8,67.2,22,66.3,22L66.3,22z"/>
                            <path fill="#FFFFFF" d="M75.6,22c-0.6,0-1.2-0.1-1.7-0.3c-0.5-0.2-0.9-0.6-1.1-1c-0.3-0.5-0.4-1-0.4-1.6c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.5-0.7,0.9-1c1.1-0.6,2.3-0.8,3.4-0.7h1.4v-0.3c0-0.3,0-0.7-0.1-1c-0.1-0.2-0.3-0.4-0.5-0.6c-0.3-0.1-0.6-0.2-0.8-0.2c-0.4,0-0.8,0.1-1.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.4h-2.7c0-0.6,0.2-1.1,0.6-1.5c0.4-0.5,0.9-0.8,1.5-1c0.7-0.2,1.5-0.3,2.3-0.3c0.8,0,1.6,0.1,2.3,0.4c0.6,0.2,1,0.7,1.3,1.2c0.3,0.7,0.5,1.4,0.4,2.1v5.8h-2.5l-0.1-1.1c-0.3,0.4-0.6,0.8-1.1,1C76.9,21.8,76.2,22,75.6,22L75.6,22z M76.6,20c0.3,0,0.7-0.1,1-0.2c0.3-0.1,0.5-0.4,0.6-0.6c0.2-0.3,0.2-0.7,0.2-1v-0.6h-1c-0.4,0-0.8,0-1.2,0.1c-0.3,0.1-0.5,0.2-0.7,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0.1,0.5c0.1,0.2,0.2,0.3,0.3,0.4C75.9,19.9,76.2,20,76.6,20L76.6,20z"/>
                            <path fill="#FFFFFF" d="M85.5,22c-0.6,0-1.2-0.1-1.7-0.3c-0.5-0.2-0.9-0.6-1.1-1c-0.3-0.5-0.4-1-0.4-1.6c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.5-0.7,0.9-1c1.1-0.6,2.3-0.8,3.5-0.8h1.4v-0.3c0-0.3,0-0.7-0.1-1c-0.1-0.2-0.3-0.4-0.5-0.5c-0.3-0.1-0.6-0.2-0.8-0.2c-0.4,0-0.8,0.1-1.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.4h-2.7c0-0.6,0.2-1.1,0.6-1.5c0.4-0.5,0.9-0.8,1.5-1c0.7-0.2,1.5-0.3,2.3-0.3c0.8,0,1.6,0.1,2.3,0.4c0.6,0.2,1,0.7,1.3,1.2c0.3,0.7,0.5,1.4,0.4,2.1v5.8h-2.6l-0.1-1.1c-0.3,0.4-0.6,0.8-1.1,1C86.8,21.8,86.1,22,85.5,22L85.5,22z M86.5,20c0.3,0,0.7-0.1,1-0.2c0.3-0.1,0.5-0.4,0.6-0.6c0.2-0.3,0.2-0.7,0.2-1v-0.6h-1c-0.4,0-0.8,0-1.2,0.1c-0.3,0.1-0.5,0.2-0.7,0.4c-0.1,0.1-0.2,0.2-0.2,0.3s-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0.1,0.5c0.1,0.2,0.2,0.3,0.3,0.4C85.8,19.9,86.1,20,86.5,20L86.5,20z"/>
                            <path fill="#FFFFFF" d="M94.7,21.7l-3-9.3h3l1.7,6.2l1.7-6.2h2.7l-3,9.3H94.7z"/>
                            <path fill="#FFFFFF" d="M101.8,21.7v-9.3h2.7v1.3c0.3-0.5,0.7-0.9,1.2-1.1c0.5-0.3,1-0.4,1.6-0.4c0.6,0,1.2,0.1,1.8,0.4c0.5,0.3,0.9,0.7,1.1,1.2c0.3,0.6,0.4,1.3,0.4,2v6h-2.8v-5.2c0-0.6-0.1-1.2-0.4-1.7c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.6,0.4c-0.3,0.5-0.5,1.1-0.4,1.7v5.1H101.8z"/>
                        </svg>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Playlist;




