/**
 * Imports
 */
import React, {useState, useRef} from 'react';
import { useDispatch } from "react-redux";

import {isEmpty, isJSON, debounce} from '../../../../utils';
import ErrorBoundary from '../../../../components/Helpers/components/container/ErrorBoundary';
import HomeView from '../presentational/Home';

import { INACTIVE, LOADING, DEFAULT_NAMES, DEFAULT_RECIPIENTS, MUSIC_TYPES, MESSAGES, LANGUAGES, RELATIONS, TONES } from '../../../../constants';

import { getPlaylistAndSaveDetails, getNameReco } from '../../../../api_handler'
import { fetchedSenderPageData } from '../../actions'

/**
 * Home page
 */
const Home = ({toggleModal, isReceiverPage}) => {

    const dispatch = useDispatch();
    const formRef = useRef();
    const [state, setState] = useState({
        hasErrors: false,
        status: INACTIVE,
        fields: {
            user_name: {
                disabled: false,
                error: '',
                required: true,
                type: 'select',
                isSearch: true,
                isSearching: false,
                value: [],
                options: DEFAULT_NAMES
            },           
            dedication: {
                disabled: false,
                error: '',
                required: true,
                type: 'select',
                isSearch: true,
                isSearching: false,
                value: [],
                options: DEFAULT_RECIPIENTS
            },           
            relation: {
                disabled: false,
                error: '',
                required: true,
                type: 'select',
                multiple: false,
                value: [],
                options: RELATIONS
            },            
            language: {
                disabled: false,
                error: '',
                required: true,
                type: 'select',
                multiple: false,
                value: [],
                options: LANGUAGES
            },
            mood: {
                disabled: false,
                error: '',
                required: true,
                type: 'select',
                multiple: false,
                value: [],
                options: MUSIC_TYPES,
            },
            message: {
                disabled: false,
                error: '',
                required: true,
                type: 'select',
                multiple: false,
                value: [],
                options: MESSAGES,
            },
            tone: {
                disabled: false,
                error: '',
                required: true,
                type: 'select',
                multiple: false,
                value: [],
                options: TONES,
            },
        },
    });
    const keys = Object.keys(state.fields);

    /**
     * Handle field validation
     */
    const handleValidation = () => {
        const fields = {};
        let hasErrors = false;

        keys.map(key => {
            const current = state.fields[key];
            const value = typeof current.value === 'object' ? (formRef.current[key]?.value ? (isJSON(formRef.current[key]?.value) ? JSON.parse(formRef.current[key]?.value) : formRef.current[key]?.value) : []) : formRef.current[key]?.value;
            const field = Object.assign({}, state.fields[key], { value, error: '' });

            if (field.required && isEmpty(field.value)) field.error = 'Field is required';

            if (!isEmpty(field.value)) {

                if (field.min !== undefined) {
                    if (field.type === 'number' && parseInt(field.value) < field.min) field.error = `Minimum of ${field.min}`;
                    if (field.type !== 'number' && field.value.length < field.min) field.error = `Minimum of ${field.min} characters`;
                }

                if (!field.error && field.max !== undefined) {
                    if (field.type === 'number' && parseInt(field.value) > field.max) field.error = `Maximum of ${(field.max)}`;
                    if (field.type !== 'number' && field.value.length > field.max) field.error = `Maximum of ${(field.max)} characters`;
                }

                if (!field.error && key === 'discount') {
                    if (formRef.current['type'].value === 'percent' && field.value > 100) field.error = 'Maximum of 100%';
                    if (formRef.current['type'].value === 'value' && field.value > 100) field.error = 'Maximum of Rs.100';
                }

                if (!field.error && field.type === 'check') {
                    if (formRef.current[`${key}-valid`]?.value !== 'true') {
                        field.error = 'Must be validated';
                    } else {
                        field.valid = true;
                    }
                }
            }

            fields[key] = field;
            hasErrors = hasErrors || field.error ? true : false;
        });

        setState(prevState => ({ ...prevState, hasErrors, status: hasErrors ? INACTIVE : LOADING, fields }));

        return hasErrors;
    };

    const handleSubmit = async (e, action=null) => {
        if (e) e.preventDefault();
        setState(prevState => ({ ...prevState, hasErrors: false, status: LOADING }));

        const hasErrors = handleValidation();

        //setTimeout( async () => {
            if (hasErrors) {
                return setState(prevState => ({ ...prevState, status: INACTIVE }));
            }

            const body = keys.reduce((a,k) => {
                if (isEmpty(formRef.current[k])) return a;
                const value = typeof state.fields[k].value === 'object' ? (isJSON(formRef.current[k].value) ? JSON.parse(formRef.current[k].value) : formRef.current[k].value) : formRef.current[k].value;
                a[k] = value; 
                return a;
            }, {});
            
            const playlistData = await getPlaylistAndSaveDetails(body);
            setState((prevState) => ({...prevState, status: INACTIVE}))
            dispatch(fetchedSenderPageData({title: playlistData.title, image: playlistData.image, songs: playlistData.songs, token: playlistData.token, receiver: playlistData.receiver, playlist_id: playlistData.playlist_id}));

            return handleUpdate();
        //});
        // handleUpdate();
    };

    const handleUpdate = () => {
        toggleModal();
    }

    const handleNameSearch = (value, fieldName) => {
        setState(prevState => (
            { ...prevState, 
                fields: { ...prevState.fields, 
                    [fieldName]: { ...prevState.fields[fieldName], isSearching: true } 
                } 
            }
        ))

        setTimeout( async() => {
            const names = await getNameReco(value);
            const parsedNames = names.length ? names.map((name) => ( {label: name, value: name} )) : [];
            parsedNames.concat(DEFAULT_NAMES);

            setState(prevState => (
                { ...prevState, 
                    fields: { ...prevState.fields, 
                        [fieldName]: { ...prevState.fields[fieldName], options: parsedNames.concat(DEFAULT_NAMES), isSearching: false } 
                    } 
                }
            ))
        })
    }

    const handleSetValue = (value, fieldName) => {
        setState(prevState => (
            { ...prevState, 
                fields: { ...prevState.fields, 
                    [fieldName]: { ...prevState.fields[fieldName], value: value} 
                } 
            }
        ))
    }



    /**
     * Component view
     */
    return (
        <ErrorBoundary>
            <HomeView {...state} toggleModal={toggleModal} formRef={formRef} handleSubmit={handleSubmit} isReceiverPage={isReceiverPage} handleNameSearch={handleNameSearch} handleSetValue={handleSetValue}/>
        </ErrorBoundary>
    );
}

export default Home;




