/**
 * Imports
 */
import { useEffect, useRef } from 'react';





/**
 * Imports
 */
const usePrevious = (value) => {

    /**
     * Properties
     */
    const ref = useRef();

    /**
     * On component update
     */
    useEffect(() => {
        ref.current = value;
    });

    /**
     * Render value
     */
    return ref.current;

};





/**
 * Default export
 */
export default usePrevious;




