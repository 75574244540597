/**
 * Imports
 */
import React, {useState, useRef} from 'react';

import PlayerView from '../presentational/PlayerView';

import { API_ENDPOINT, ADDITIONAL_PARAMS } from '../../../api_handler';
import { MESSAGE_MAPPINGS } from "../../../constants";


/**
 * Home page view
 */
const Player = ({receiver, sender, message, voice, handlePlaylistClickEvent}) => {

    const playerRef = useRef();
    const svgRef = useRef();
    const animationTTS = useRef();
    const [state, setState] = useState({
        isPlaying: false,
        isFileLoaded: false
    })

    const handleTimeUpdate = (e) => {
        const rawPaths = svgRef.current.querySelectorAll('path');
        const paths = Array.from(rawPaths).reverse();
        // set duration to 10 seconds if it returns as Infinity. This is to get around chrome bug.
        const duration = playerRef.current.duration === Infinity ? 10 : playerRef.current.duration;
        const percent = playerRef.current.currentTime / duration;
        const pathDuration = Math.floor(paths.length * percent);
        paths.forEach((path, i) => {
            if (i < pathDuration) {
                path.style = 'fill: #2bc5b4';
            } else {
                path.style = 'fill: #fff';
            }
        })
    }

    const handlePlay = () => {

        handlePlaylistClickEvent("TTS Play Pause");

        // Fetching TTS Response when the user clicks on the Play Button for the first time.
        if(!state.isFileLoaded){
            var tts = document.getElementById('text-to-speech');

            var text = 'Hey ' + receiver + ', ' + MESSAGE_MAPPINGS[Number(message)];
            switch(Number(message)) {
                case 0:
                case 1: 
                    text += ' I’ve made this Valentine’s Day Jam just for you! \n With love, ' + sender;
                    break;
                case 2:
                case 3:
                    text += ' How about you, me, and this Valentine’s Day Jam? Love, ' + sender;
                    break;
                case 4:
                case 5:
                    text += ' Here’s a Valentine’s Day Jam that I think you’ll enjoy. All my love, ' + sender;
                    break;
            }

            tts.innerHTML = '<source src="' + API_ENDPOINT + '__call=centerfresh.getTTSResponse&voice=' + voice + '&message=' + text + ADDITIONAL_PARAMS + '">';
            setState((prevState) => ({...prevState, isFileLoaded: true, isPlaying: true}));
        }

        if (state.isPlaying) {
            setState((prevState) => ({...prevState, isPlaying: false}))
            playerRef.current.pause();
        } else {
            setState((prevState) => ({...prevState, isPlaying: true}))
            playerRef.current.play();            
        }
    }

    /**
     * Component view
     */
    return (
        <PlayerView playerRef={playerRef} handlePlay={handlePlay} svgRef={svgRef} handleTimeUpdate={handleTimeUpdate} {...state} />
    );
}

export default Player;