/**
 * Imports
 */
import React from 'react';






/**
 * Home page view
 */
const ModalView = ({children, modalOpen, toggleModal}) => {

    /**
     * Component view
     */
    return (
        <div className={`c-modal ${modalOpen ? 'open' : ''}`}>
            {children}
        </div>
    );
}

export default ModalView;




