/**
 * Imports
 */
import React from 'react';



/**
 * Home page view
 */
const PlayerView = ({playerRef, handlePlay, isPlaying, handleTimeUpdate, svgRef}) => {

    /**
     * Component view
     */
    return (
        <div className="o-layout o-layout--middle o-layout--stretch">
            <div className="o-layout__item u-1/4">
                <div className="o-pin o-pin--small u-background-white" onClick={handlePlay}>
                    <span className={`o-icon-${isPlaying ? 'pause' : 'play'} u-color-js-navy`}></span>
                </div>
            </div>
            <div className="o-layout__item u-3/4" ref={svgRef}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 262.053 47.873">
                  <g id="vecteezy_a-graphic-collection-of-black-sound-waves-electronic-wave_6329409_Converted_copy-02" data-name="vecteezy_a-graphic-collection-of-black-sound-waves-electronic-wave_6329409 [Converted] copy-02" transform="translate(262.053 47.873) rotate(180)">
                    <path id="Line_4" data-name="Line 4" d="M1.372,5.991H0V0H1.372Z" transform="translate(0 20.941)" fill="#fff"/>
                    <path id="Line_5" data-name="Line 5" d="M1.372,9.89H0V0H1.372Z" transform="translate(2.742 18.991)" fill="#fff"/>
                    <path id="Line_6" data-name="Line 6" d="M1.372,12.835H0V0H1.372Z" transform="translate(5.504 17.512)" fill="#fff"/>
                    <path id="Line_7" data-name="Line 7" d="M1.372,9.322H0V0H1.372Z" transform="translate(8.245 19.276)" fill="#fff"/>
                    <path id="Line_8" data-name="Line 8" d="M1.372,16.592H0V0H1.372Z" transform="translate(10.987 15.64)" fill="#fff"/>
                    <path id="Line_9" data-name="Line 9" d="M1.372,4.752H0V0H1.372Z" transform="translate(13.729 21.553)" fill="#fff"/>
                    <path id="Line_10" data-name="Line 10" d="M1.372,13.465H0V0H1.372Z" transform="translate(16.47 17.204)" fill="#fff"/>
                    <path id="Line_11" data-name="Line 11" d="M1.372,8.509H0V0H1.372Z" transform="translate(19.212 19.682)" fill="#fff"/>
                    <path id="Line_12" data-name="Line 12" d="M1.372,4.752H0V0H1.372Z" transform="translate(21.974 21.553)" fill="#fff"/>
                    <path id="Line_13" data-name="Line 13" d="M1.372,11.109H0V0H1.372Z" transform="translate(24.716 18.382)" fill="#fff"/>
                    <path id="Line_14" data-name="Line 14" d="M1.372,13.729H0V0H1.372Z" transform="translate(27.457 17.065)" fill="#fff"/>
                    <path id="Line_15" data-name="Line 15" d="M1.372,16.47H0V0H1.372Z" transform="translate(30.199 15.701)" fill="#fff"/>
                    <path id="Line_16" data-name="Line 16" d="M1.372,11.109H0V0H1.372Z" transform="translate(32.941 18.382)" fill="#fff"/>
                    <path id="Line_17" data-name="Line 17" d="M1.372,13.729H0V0H1.372Z" transform="translate(35.682 17.065)" fill="#fff"/>
                    <path id="Line_18" data-name="Line 18" d="M1.372,28.373H0V0H1.372Z" transform="translate(38.424 9.75)" fill="#fff"/>
                    <path id="Line_19" data-name="Line 19" d="M1.372,15.8H0V0H1.372Z" transform="translate(41.186 16.024)" fill="#fff"/>
                    <path id="Line_20" data-name="Line 20" d="M1.372,22.383H0V0H1.372Z" transform="translate(43.927 12.734)" fill="#fff"/>
                    <path id="Line_21" data-name="Line 21" d="M1.372,16.47H0V0H1.372Z" transform="translate(46.669 15.701)" fill="#fff"/>
                    <path id="Line_22" data-name="Line 22" d="M1.372,15.8H0V0H1.372Z" transform="translate(49.411 16.024)" fill="#fff"/>
                    <path id="Line_23" data-name="Line 23" d="M1.372,33.738H0V0H1.372Z" transform="translate(52.152 7.067)" fill="#fff"/>
                    <path id="Line_24" data-name="Line 24" d="M1.372,30.526H0V0H1.372Z" transform="translate(54.894 8.673)" fill="#fff"/>
                    <path id="Line_25" data-name="Line 25" d="M1.372,25.855H0V0H1.372Z" transform="translate(57.656 11.009)" fill="#fff"/>
                    <path id="Line_26" data-name="Line 26" d="M1.372,15.8H0V0H1.372Z" transform="translate(60.398 16.024)" fill="#fff"/>
                    <path id="Line_27" data-name="Line 27" d="M1.372,19.926H0V0H1.372Z" transform="translate(63.139 13.973)" fill="#fff"/>
                    <path id="Line_28" data-name="Line 28" d="M1.372,19.76H0V0H1.372Z" transform="translate(65.881 14.056)" fill="#fff"/>
                    <path id="Line_29" data-name="Line 29" d="M1.372,14.541H0V0H1.372Z" transform="translate(68.623 16.659)" fill="#fff"/>
                    <path id="Line_30" data-name="Line 30" d="M1.372,9.89H0V0H1.372Z" transform="translate(71.364 18.991)" fill="#fff"/>
                    <path id="Line_31" data-name="Line 31" d="M1.372,25.855H0V0H1.372Z" transform="translate(74.106 11.009)" fill="#fff"/>
                    <path id="Line_32" data-name="Line 32" d="M1.372,16.47H0V0H1.372Z" transform="translate(76.868 15.701)" fill="#fff"/>
                    <path id="Line_33" data-name="Line 33" d="M1.372,19.76H0V0H1.372Z" transform="translate(79.61 14.056)" fill="#fff"/>
                    <path id="Line_34" data-name="Line 34" d="M1.372,25.855H0V0H1.372Z" transform="translate(82.351 11.009)" fill="#fff"/>
                    <path id="Line_35" data-name="Line 35" d="M1.372,30.526H0V0H1.372Z" transform="translate(85.093 8.673)" fill="#fff"/>
                    <path id="Line_36" data-name="Line 36" d="M1.372,16.47H0V0H1.372Z" transform="translate(87.753 15.701)" fill="#fff"/>
                    <path id="Line_37" data-name="Line 37" d="M1.372,12.205H0V0H1.372Z" transform="translate(90.515 17.834)" fill="#fff"/>
                    <path id="Line_38" data-name="Line 38" d="M1.372,19.76H0V0H1.372Z" transform="translate(93.257 14.056)" fill="#fff"/>
                    <path id="Line_39" data-name="Line 39" d="M1.372,25.49H0V0H1.372Z" transform="translate(95.999 11.192)" fill="#fff"/>
                    <path id="Line_40" data-name="Line 40" d="M1.372,19.76H0V0H1.372Z" transform="translate(98.74 14.056)" fill="#fff"/>
                    <path id="Line_41" data-name="Line 41" d="M1.372,19.76H0V0H1.372Z" transform="translate(101.482 14.056)" fill="#fff"/>
                    <path id="Line_42" data-name="Line 42" d="M1.372,16.47H0V0H1.372Z" transform="translate(104.224 15.701)" fill="#fff"/>
                    <path id="Line_43" data-name="Line 43" d="M1.372,12H0V0H1.372Z" transform="translate(106.986 17.935)" fill="#fff"/>
                    <path id="Line_44" data-name="Line 44" d="M1.372,28.9H0V0H1.372Z" transform="translate(109.727 9.486)" fill="#fff"/>
                    <path id="Line_45" data-name="Line 45" d="M1.372,15.8H0V0H1.372Z" transform="translate(112.469 16.024)" fill="#fff"/>
                    <path id="Line_46" data-name="Line 46" d="M1.372,19.76H0V0H1.372Z" transform="translate(115.211 14.056)" fill="#fff"/>
                    <path id="Line_47" data-name="Line 47" d="M1.372,16.47H0V0H1.372Z" transform="translate(117.952 15.701)" fill="#fff"/>
                    <path id="Line_48" data-name="Line 48" d="M1.372,8.225H0V0H1.372Z" transform="translate(120.694 19.824)" fill="#fff"/>
                    <path id="Line_49" data-name="Line 49" d="M1.372,10.5H0V0H1.372Z" transform="translate(123.436 18.687)" fill="#fff"/>
                    <path id="Line_50" data-name="Line 50" d="M1.372,7.128H0V0H1.372Z" transform="translate(126.198 20.372)" fill="#fff"/>
                    <path id="Line_51" data-name="Line 51" d="M1.372,11.738H0V0H1.372Z" transform="translate(128.939 18.06)" fill="#fff"/>
                    <path id="Line_52" data-name="Line 52" d="M1.372,21H0V0H1.372Z" transform="translate(131.681 13.43)" fill="#fff"/>
                    <path id="Line_53" data-name="Line 53" d="M1.372,26.18H0V0H1.372Z" transform="translate(134.423 10.846)" fill="#fff"/>
                    <path id="Line_54" data-name="Line 54" d="M1.372,15.8H0V0H1.372Z" transform="translate(137.164 16.024)" fill="#fff"/>
                    <path id="Line_55" data-name="Line 55" d="M1.372,33.738H0V0H1.372Z" transform="translate(139.906 7.067)" fill="#fff"/>
                    <path id="Line_56" data-name="Line 56" d="M1.372,27.541H0V0H1.372Z" transform="translate(142.668 10.161)" fill="#fff"/>
                    <path id="Line_57" data-name="Line 57" d="M1.372,21H0V0H1.372Z" transform="translate(145.409 13.43)" fill="#fff"/>
                    <path id="Line_58" data-name="Line 58" d="M1.372,14.683H0V0H1.372Z" transform="translate(148.151 16.595)" fill="#fff"/>
                    <path id="Line_59" data-name="Line 59" d="M1.372,17.973H0V0H1.372Z" transform="translate(150.893 14.95)" fill="#fff"/>
                    <path id="Line_60" data-name="Line 60" d="M1.372,21.405H0V0H1.372Z" transform="translate(153.634 13.227)" fill="#fff"/>
                    <path id="Line_61" data-name="Line 61" d="M1.372,10.5H0V0H1.372Z" transform="translate(156.376 18.687)" fill="#fff"/>
                    <path id="Line_62" data-name="Line 62" d="M1.372,14.683H0V0H1.372Z" transform="translate(159.118 16.595)" fill="#fff"/>
                    <path id="Line_63" data-name="Line 63" d="M1.372,16.47H0V0H1.372Z" transform="translate(161.88 15.701)" fill="#fff"/>
                    <path id="Line_64" data-name="Line 64" d="M1.372,10.5H0V0H1.372Z" transform="translate(164.621 18.687)" fill="#fff"/>
                    <path id="Line_65" data-name="Line 65" d="M1.372,16.47H0V0H1.372Z" transform="translate(167.363 15.701)" fill="#fff"/>
                    <path id="Line_66" data-name="Line 66" d="M1.372,12.693H0V0H1.372Z" transform="translate(170.105 17.59)" fill="#fff"/>
                    <path id="Line_67" data-name="Line 67" d="M1.372,25.124H0V0H1.372Z" transform="translate(172.846 11.374)" fill="#fff"/>
                    <path id="Line_68" data-name="Line 68" d="M1.372,17.973H0V0H1.372Z" transform="translate(175.588 14.95)" fill="#fff"/>
                    <path id="Line_69" data-name="Line 69" d="M1.372,28.9H0V0H1.372Z" transform="translate(178.35 9.486)" fill="#fff"/>
                    <path id="Line_70" data-name="Line 70" d="M1.372,31.1H0V0H1.372Z" transform="translate(181.092 8.387)" fill="#fff"/>
                    <path id="Line_71" data-name="Line 71" d="M1.372,31.1H0V0H1.372Z" transform="translate(183.833 8.387)" fill="#fff"/>
                    <path id="Line_72" data-name="Line 72" d="M1.372,40.46H0V0H1.372Z" transform="translate(186.575 3.696)" fill="#fff"/>
                    <path id="Line_73" data-name="Line 73" d="M1.372,47.873H0V0H1.372Z" transform="translate(189.317 0)" fill="#fff"/>
                    <path id="Line_74" data-name="Line 74" d="M1.372,30.526H0V0H1.372Z" transform="translate(192.058 8.673)" fill="#fff"/>
                    <path id="Line_75" data-name="Line 75" d="M1.372,27.564H0V0H1.372Z" transform="translate(194.8 10.154)" fill="#fff"/>
                    <path id="Line_76" data-name="Line 76" d="M1.372,25.063H0V0H1.372Z" transform="translate(197.562 11.399)" fill="#fff"/>
                    <path id="Line_77" data-name="Line 77" d="M1.372,32.537H0V0H1.372Z" transform="translate(200.304 7.663)" fill="#fff"/>
                    <path id="Line_78" data-name="Line 78" d="M1.372,12.693H0V0H1.372Z" transform="translate(203.045 17.59)" fill="#fff"/>
                    <path id="Line_79" data-name="Line 79" d="M1.372,16.47H0V0H1.372Z" transform="translate(205.787 15.701)" fill="#fff"/>
                    <path id="Line_80" data-name="Line 80" d="M1.372,8.225H0V0H1.372Z" transform="translate(208.529 19.824)" fill="#fff"/>
                    <path id="Line_81" data-name="Line 81" d="M1.372,12.693H0V0H1.372Z" transform="translate(211.27 17.59)" fill="#fff"/>
                    <path id="Line_82" data-name="Line 82" d="M1.372,16.47H0V0H1.372Z" transform="translate(214.012 15.701)" fill="#fff"/>
                    <path id="Line_83" data-name="Line 83" d="M1.372,21.141H0V0H1.372Z" transform="translate(216.774 13.366)" fill="#fff"/>
                    <path id="Line_84" data-name="Line 84" d="M1.372,16.47H0V0H1.372Z" transform="translate(219.515 15.701)" fill="#fff"/>
                    <path id="Line_85" data-name="Line 85" d="M1.372,12.693H0V0H1.372Z" transform="translate(222.257 17.59)" fill="#fff"/>
                    <path id="Line_86" data-name="Line 86" d="M1.372,8.225H0V0H1.372Z" transform="translate(224.999 19.824)" fill="#fff"/>
                    <path id="Line_87" data-name="Line 87" d="M1.372,12.693H0V0H1.372Z" transform="translate(227.74 17.59)" fill="#fff"/>
                    <path id="Line_88" data-name="Line 88" d="M1.372,26.992H0V0H1.372Z" transform="translate(230.482 10.44)" fill="#fff"/>
                    <path id="Line_89" data-name="Line 89" d="M1.372,20.045H0V0H1.372Z" transform="translate(233.244 13.914)" fill="#fff"/>
                    <path id="Line_90" data-name="Line 90" d="M1.372,24.94H0V0H1.372Z" transform="translate(235.986 11.466)" fill="#fff"/>
                    <path id="Line_91" data-name="Line 91" d="M1.372,12.774H0V0H1.372Z" transform="translate(238.727 17.549)" fill="#fff"/>
                    <path id="Line_92" data-name="Line 92" d="M1.372,20.045H0V0H1.372Z" transform="translate(241.469 13.914)" fill="#fff"/>
                    <path id="Line_93" data-name="Line 93" d="M1.372,16.47H0V0H1.372Z" transform="translate(244.211 15.701)" fill="#fff"/>
                    <path id="Line_94" data-name="Line 94" d="M1.372,30.77H0V0H1.372Z" transform="translate(246.952 8.552)" fill="#fff"/>
                    <path id="Line_95" data-name="Line 95" d="M1.372,16.47H0V0H1.372Z" transform="translate(249.694 15.701)" fill="#fff"/>
                    <path id="Line_96" data-name="Line 96" d="M1.372,17.973H0V0H1.372Z" transform="translate(252.456 14.95)" fill="#fff"/>
                    <path id="Line_97" data-name="Line 97" d="M1.372,10.581H0V0H1.372Z" transform="translate(255.198 18.646)" fill="#fff"/>
                    <path id="Line_98" data-name="Line 98" d="M1.372,8.225H0V0H1.372Z" transform="translate(257.939 19.824)" fill="#fff"/>
                    <path id="Line_99" data-name="Line 99" d="M1.372,6.235H0V0H1.372Z" transform="translate(260.681 20.819)" fill="#fff"/>
                  </g>
                </svg>

            </div>
            <audio id="text-to-speech" className="u-hidden" ref={playerRef} onEnded={handlePlay} onTimeUpdate={handleTimeUpdate} />
        </div>
    );
}

export default PlayerView;




