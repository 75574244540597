/**
 * Imports
 */
import React from 'react';
import Logo from '../../../Helpers/components/presentational/Logo';
import { Link } from 'react-router-dom';

import { formatBotSrcEventData, logEvent } from './../../../../events/eventsUtils'



/**
 * Footer component
 */
const Footer = ({isReceiverPage}) => {

    function handleFooterClickEvent(entity) {
        const event_data = formatBotSrcEventData({
            event_type: "click",
            bot_src: {
                screen_name: isReceiverPage ? "centerfresh_receiver_screen" : "centerfresh_sender_screen",
                sec_title: 'footer',
                entity_name: entity,
                entity_id: entity.replace(/\s+/g, '_').toLowerCase(),
                entity_type: "button",
            }
        });
        logEvent(event_data);
    }

    /**
     * Component view
     */
    return (
        <section className="c-footer u-color-white">
            <div className="o-wrapper c-footer__contents">
                <footer className="c-footer__nav">
                    <div className="o-layout o-layout--middle@md">
                        <div className="o-layout__item u-1/5@md u-1/2 u-margin-bottom-large u-margin-bottom-none@md" style={{fontSize: 0}} onClick={() => handleFooterClickEvent('Logo')}>
                            <Logo />
                        </div>
                        <div className="o-layout__item u-3/5@md u-margin-bottom-large u-margin-bottom-none@md">
                            <Link to="/" className="c-footer__item" onClick={() => handleFooterClickEvent('Home')}>
                                Home
                            </Link>
                            <Link to="/faq" className="c-footer__item" onClick={() => handleFooterClickEvent('FAQs')}>
                                FAQs
                            </Link>
                            <Link to="/terms" className="c-footer__item" onClick={() => handleFooterClickEvent('T&Cs')}>
                                T&Cs
                            </Link>
                        </div>
                        <div className="o-layout__item u-1/5@md u-margin-bottom-large u-margin-bottom-none@md">
                            <a href="https://www.facebook.com/Centerfreshindia/?mibextid=ZbWKwL " target="_blank" className="u-margin-right-small o-pin o-pin--medium u-background-dark-blue u-color-white" onClick={() => handleFooterClickEvent('Facebook')}><span className="o-icon-facebook u-color-dark-teal"></span></a>
                            <a href="https://twitter.com/CenterfreshIn?t=osD2fRCnnXVt5rX1b872Gg&s=09" target="_blank" className="u-margin-right-small o-pin o-pin--medium u-background-dark-blue u-color-white" onClick={() => handleFooterClickEvent('Twitter')}><span className="o-icon-twitter u-color-dark-teal"></span></a>
                            <a href="https://instagram.com/centerfresh_india?igshid=NTdlMDg3MTY=" target="_blank" className="u-margin-right-small o-pin o-pin--medium u-background-dark-blue u-color-white" onClick={() => handleFooterClickEvent('Instagram')}><span className="o-icon-instagram u-color-dark-teal"></span></a>
                        </div>
                    </div>
                </footer>
            </div>
        </section>
    );

}
export default Footer;



