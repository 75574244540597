import Cookies from 'universal-cookie';

var cookies = new Cookies();

export function getCookie(cookie_name) {

	if (window) {
		return cookies.get(cookie_name);
	} else {
		return global.cookieObj[cookie_name];
	}
}

export function getGeoCookieData() {

	let geo = '';
	if (window) {
		geo = getCookie("geo");
	} else {
		geo = global.cookieObj["geo"];
	}

	let geoValues = null;
	if (geo != null) {
		geoValues = geo.split(",");
		let geo_cc = geoValues == null ? 'NULL' : geoValues[1];
		let geo_state = geoValues == null ? 'NULL' : geoValues[2];
		let geo_city = geoValues == null ? 'NULL' : geoValues[3];
		return {"country": geo_cc, "state": geo_state, "city": geo_city};
	}

	return {};
}