/**
 * Imports
 */
import React, { useEffect, useState, useRef } from 'react';
import { renderToString } from 'react-dom/server'

import Accordion from '../../../../components/Helpers/components/presentational/Accordion';
import Select from '../../../../components/Select/components/Container/Select';

import { formatBotSrcEventData, logEvent } from '../../../../events/eventsUtils';
import { DUMMY_FAQ as faqData } from '../../../../constants';

/**
 * Home page view
 */
const FaqView = ({}) => {

    /**
     * Properties
     */
    const [accordionsOpen, setAccordionsOpen] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [stickyNav, setStickyNav] = useState(false);
    const [stickyPoint, setStickypoint] = useState();
    const navListCtr = useRef(null);
    const navList = useRef(null);
    const nextSection = useRef(null);
    const [bottomedOut, setBottomedOut] = useState(false);
    const initialList = faqData['Getting Started']
    const initalCategory = 'Getting Started'
    const [state, setState] = useState({list: initialList, category: initalCategory } );
    const stateRef = useRef(state);

    /**
     * On page load
     */
    useEffect(() => {
        window.addEventListener("scroll", scrollListener);

        const view_event_data = formatBotSrcEventData({
            event_type : "view",
            bot_src: {
                screen_name: "centerfresh_faq_screen"
            }
        });
        logEvent(view_event_data);

        return () => {
            window.removeEventListener("scroll", scrollListener);
        };
    }, []);

    /**
     * On state change, update stateRef (used for scroll listener)
     */
    useEffect(() => {
        stateRef.current = state
    }, [state])

    /**
     * Scroll Listener
     */
    const scrollListener = () => {
        const offset = 30;
        const offsetBottom = 80;
        let y = window.scrollY + offset;
        let top = window.pageYOffset + navListCtr.current.getBoundingClientRect().top;
        let bottom = window.pageYOffset + nextSection.current.getBoundingClientRect().top;
        let navHeight = navList.current.offsetHeight;

        if (y >= top && stateRef.current.list.length >= 4) {
            setStickyNav(true);

            if (y + navHeight + offsetBottom >= bottom) {
                setBottomedOut(true);
                setStickyNav(false);
            } else {
                setBottomedOut(false);
            }

        } else {
            setBottomedOut(false);
            setStickyNav(false)
        }
    }

    /**
     * Scroll to top of faqs, then change category
     */
    const smoothSetCategory = (cat) => {
        let top = window.pageYOffset + navListCtr.current.getBoundingClientRect().top;
        window.scroll({
            top: top - 20,
            behavior: 'smooth'
        })
        window.setTimeout(function() {
            setState((prevState) => ({...prevState, category: cat, list: faqData[cat] }) )
            setBottomedOut(false);
            setStickyNav(false)
        }, 500)
    }

    /**
     * Get html copy as plaintext. 
     */
    const getHtmlAsPlaintext = (copy) => {
        const plaintext = renderToString(copy);
        return plaintext.replace(/<(.|\n)*?>/g, '');
    }

    /**
     * Render view
     */
    return (
        <main className='scroll u-background-dark-blue u-color-white'>

            <section className="o-layout o-layout--middle o-layout--flush u-background-darker-blue u-position-relative u-padding-vertical-huge@md u-padding-vertical-large angle">
                    <div className="o-layout__item u-3/5@md f-z-index">
                    <div className="o-wrapper">
                        <h1 className="u-color-white">Frequently Asked Questions</h1>
                    </div>
                </div>
            </section>

            <section id="faq-body" className="u-padding-vertical-huge@md u-padding-bottom-large u-position-relative">
                <div className="o-wrapper">
                    <div className="o-layout o-layout--center u-position-relative" ref={navListCtr}>
                        <aside className={`o-layout__item u-visible-visually@md u-1/3 u-hidden`}>
                            <div ref={navList} className={`c-sticky-nav`} style={{position: `${stickyNav ? 'fixed' : bottomedOut ? 'absolute' : 'relative'}`, top: `${stickyNav ? '30px' : ''}`, bottom: `${bottomedOut ? '0' : ''}`} }>
                                <h6 className="u-margin-bottom-large">Category</h6>
                                <ul className="o-list-bare o-list-bare--nav">
                                    { Object.keys(faqData).map((cat, i) => (
                                        <li key={i} onClick={() => {  smoothSetCategory(cat); } } className={`o-list-bare__item u-cursor-pointer ${cat !== state.category ? 'u-opaque' : 'active'}`}>
                                            <h5 className={`u-inline-block u-cursor-pointer`}>{cat}</h5>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </aside>
                        <div className={`o-layout__item u-2/3@md`}>
                            <div className={`o-layout u-margin-bottom  o-layout--middle o-layout--top@md o-layout--flush u-hidden`}>
                                <div className="o-layout__item u-1/1">
                                    <h6 className="u-margin-bottom-none u-visible@md">{state.category}</h6>
                                    <Select
                                        className={`u-hidden@md u-margin-bottom`}
                                        id="categories"
                                        name="categories"
                                        options={ Object.keys(faqData).map((label,value) => ({ label, value: label })) }
                                        selected={state.category ? state.category : ''}
                                        placeholder="Select a Category"
                                        handleSelection={({ value }) => setState((prevState) => ({...prevState, category: value, list: faqData[value]}) ) } />
                                </div>
                                <div className="o-layout__item u-1/1 u-align-right">
                                    <button className="c-btn c-btn--full@sm c-btn--secondary u-cursor-pointer u-centi" onClick={() => setAccordionsOpen((prevState) => !prevState) }>{accordionsOpen ?  '- Collapse All' : '+ Expand All'}</button>
                                </div>
                            </div>
                            {state.list?.length ? 
                                state.list.map((item, i) => (
                                    <Accordion item={item} key={item.title} open={accordionsOpen} isSmall={true}/>
                                ))
                                : 
                                <div className="u-padding-vertical-huge u-align-center">
                                    <div className="u-margin-bottom-huge">
                                        <span className="o-icon-noresults o-icon--enormous u-color-js-gray-alt"></span>
                                    </div>
                                    <h3 className="u-margin-bottom-none">No Results Found</h3>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section className="u-color-black u-background-f-gray-alt u-padding-none u-no-scroll-snap" ref={nextSection}>
            </section>
        </main>
    );

}

export default FaqView;




