/**
 * Imports
 */
import React from 'react';






/**
 * Home page view
 */
const Toast = ({isActive}) => {

    /**
     * Component view
     */
    return (
        <div className={`c-toast ${isActive ? 'open' : ''}`}>
            <div className="o-flag">
                <div className="o-flag__img">
                    <span className="o-pin o-pin--tiny">
                        <span className="o-icon-check"></span>
                    </span>
                </div>
                <div className="o-flag__body">
                    <p className="">Success</p>
                    <p className="">Link has been copied to clipboard. Share now!</p>
                </div>
            </div>
        </div>
    );
}

export default Toast;




