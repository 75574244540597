/**
 * Imports
 */
import { useState } from 'react';





/**
 * Marketing Page Accordions
 */
export default function AccordionRow(props) {

    /**
     * Properties
     */
    const [open, setOpen] = useState(false);

    /**
     * Render view
     */
    return (
        <li className={`c-accordion ${open || props.open ? 'open' : ''} ${props.isSmall ? 'c-accordion--small' : ''}`} onClick={() => setOpen(!open)}>
            {!props.isSmall && <div className="f-bg-grain" style={{backgroundImage: "url('/_i/homepage/noise.png')"}} ></div>}
            <div className={`o-wrapper ${props.isSmall ? 'u-padding-none' : ''}`}>
                <div className="c-accordion__outer">
                    <h4 className={open || props.open ? `u-margin-bottom`: 'u-margin-bottom-none'}>{props.item.title}</h4>
                    <span className="u-cursor-pointer">
                        <div className={`c-accordion__btn ${open || props.open ? 'close' : ''}`}>
                            <span></span>
                            <span></span>
                        </div>
                    </span>
                </div>
                <div className="c-accordion__inner">
                    {props.item.section && <div className="u-deci u-margin-bottom">({props.item.section})</div>}
                    {props.isSmall ? 
                        <div className="u-color-white" dangerouslySetInnerHTML={{__html: props.item.copy}}></div>
                        :
                        <div className="u-color-white">{props.item.copy}</div>
                    }
                </div>
            </div>
        </li>
    );

}




