/**
 * Imports
 */
import React from 'react';

import { camelCase, isEqual } from '../../../../utils';





/**
 * Select options
 *
 * @param {object} props
 */
const SelectView = (props) => {

    /**
     * Properties
     */
    const { handleClick, handleFilter, handleOnFocus, handleSelection } = props;
    const { className, id, icon, list, multiple, isSearch, max, min, name, options, placeholder, selected, term, value, handleSetTerm, isSearching } = props;
    const values = selected.length ? selected : '';

    /**
     * Set selected element
     */
    const setSelections = () => {
        if (!multiple || !selected.length) return;

        const selections = options.filter(o => selected.indexOf(o.value) !== -1);

        return selections.map(s => (
            <button key={`sb-${s.value}`} type="button" className="c-btn c-btn--tertiary c-btn--small u-margin-bottom-small u-margin-right-small">
                <span className=" u-valign-middle u-margin-right-small">{s.label}</span>
                <span onClick={() => handleSelection({ name, value: s.value })} className="o-icon-close o-icon--circle u-valign-middle"></span>
            </button>
        ));
    };

    /**
     * Set selected element
     */
    const setSelected = () => {

        if (!multiple && !isSearch) {
            if (selected.length) return selected[0];
            return <span className="u-color-js-gray-alt c-select__placeholder">{placeholder || `Select ${name}`}</span>;
        }

        return (
            <input
                className="c-select__search"
                id={`select-search-${name}`}
                name={`select-search-${name}`}
                placeholder={placeholder || 'Type to search'}
                type="search"
                value={term}
                onChange={(e) => handleFilter(e.target.value)} />
        );
    };

    /**
     * Set icon, if exists
     */
    const setIcon = () => {
        if (icon) return <i className={`o-icon-${icon} o-icon--large`}></i>;
    };

    /**
     * Set list item based on props
     */
    const setOptions = () => {
        if (isSearch && isSearching) return <li className="c-select__item u-align-center"><span className="c-loader"></span></li>;
        if (!list.length) return <li className="c-select__item disabled u-align-center">No results found</li>;
        return list.map((option, i) => (
            <li
                key={option.value + i}
                className={selected.includes(option.value) ? 'c-select__item active' : 'c-select__item'}
                onClick={() => {
                    if (option.value !== 'inactive') {
                        handleSelection({ name, value: option.value })
                        isSearch && handleSetTerm(option.value);
                    }
                }}>
                {option.label}
            </li>
        ));
    };

    /**
     * Component rendering
     */
    return (
        <>
            <div className={className}>
                <input className="c-select__input"
                    readOnly
                    id={id}
                    name={name}
                    type="text"
                    value={JSON.stringify(values || [])}
                    onFocus={handleOnFocus} />

                <div className="c-select__select" onClick={handleClick}>
                    {setIcon()}
                    {setSelected()}
                </div>

                <div className="c-select__list">
                    {setOptions()}
                </div>
            </div>

            {setSelections()}
        </>
    );
}





/**
 * Default export
 */
export default React.memo(SelectView);




